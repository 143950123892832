import {useState} from "react";
import {Button} from "@mui/material";
import {Dialog} from "primereact/dialog";
import {InputNumber} from "primereact/inputnumber";

interface LoadOtherLAPProps {
    currentPuzzleNumber: number;
}

export function LoadOtherLAP(props: LoadOtherLAPProps) {
    const {currentPuzzleNumber} = props;
    const [showingDialog, setShowingDialog] = useState(false);
    const [puzzleNumber, setPuzzleNumber] = useState(currentPuzzleNumber);

    return <>
        <Button variant='contained' style={{marginLeft: '10px'}} onClick={() => setShowingDialog(true)} >Load...</Button>
        <Dialog
            header="Load Previous Puzzles"
            visible={showingDialog}
            onHide={() => setShowingDialog(false)}
            modal={true}
            footer={<Button onClick={() => setShowingDialog(false)} >Close</Button>}
        >
            <InputNumber value={puzzleNumber} onChange={(e) => setPuzzleNumber(e.value || 0)} />
            <Button variant='contained' style={{marginLeft: '10px'}}
                    onClick={() => {
                window.location.href = `/games/LAP/${puzzleNumber}`;
            }}>Go!</Button>
        </Dialog>
    </>
}