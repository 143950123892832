import React, {ReactElement, useEffect, useState} from "react";
import PlayerHelper from "../../players/PlayerHelper";
import './Tabs.scss';

interface TabsProps {
    players: any[];
    renderTabContent: (player: any, playerIndex: number) => ReactElement;
    additionalTabStuff?: ReactElement;
}

export function Tabs(props: TabsProps) {
    const {players, renderTabContent, additionalTabStuff} = props;
    const [tab, setTab] = useState(players.length);

    useEffect(() => { // Reset view when player count changes.
        setTab(players.length);
    }, [players.length]);

    return <div className='tab-control'>
        <div className='tabs'>
            {players.map((p, i) =>
                <div
                    className={`
                        playerTab
                        ${tab === i ? 'active' : ''}
                        ${PlayerHelper.getStyleClass(p)}
                    `}
                    key={i}
                    onClick={() => setTab(i)}>
                    <img src={PlayerHelper.getAvatar(p)} alt='avatar'/>
                </div>
            )}
            <div
                className={`playerTab ${tab === players.length ? 'active' : ''}`}
                key={players.length}
                onClick={() => setTab(players.length)}>
                all
            </div>
            {additionalTabStuff}
        </div>
        <div className='tab-content-container p-grid'>
            {players.map((player, i) =>
                <div
                    key={i}
                    className={`
                      tab-content
                      p-col-${tab === players.length ? Math.round(12 / players.length) : 12}
                      ${PlayerHelper.getStyleClass(player)}
                      ${tab === i || tab === players.length ? '' : ' hideTab'}`}>
                    {renderTabContent(player, i)}
                </div>)}
        </div>
    </div>
}
