import sequenciumPreviewImg from '../../images/sequencium-preview.png'
import {Sequencium} from '../../common-build/games/Sequencium'
import SequenciumInstructions from './SequenciumInstructions'
import SequenciumCanvas from './SequenciumCanvas'
import {GameEntry} from '../GameEntries'

export const SequenciumEntry: GameEntry = {
  canonicalName: "sequencium",
  displayName: "Sequencium",
  tagline: 'A battle of the vines.',
  image: sequenciumPreviewImg,
  GameClass: Sequencium,
  instructions: SequenciumInstructions.getInstructionCards(),
  Canvas: SequenciumCanvas
}
