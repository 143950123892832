import {useEffect, useState} from 'react';
import {httpGet} from "./Http";


const useXhr = (defaultValue, path, query) => {
  const [value, setValue] = useState(defaultValue);
  const queryString = JSON.stringify(query);

  useEffect(() => {
    httpGet(path).query(JSON.parse(queryString)).then(
        rsp => setValue(rsp.body),
        err => console.log(err));
  }, [path, queryString]);

  return value;
};


export default useXhr;
