import dandelionsPreviewImg from '../../images/dandelions-preview.png'
import DandelionsInstructions from './DandelionsInstructions'
import DandelionsCanvas from './DandelionsCanvas'
import {Dandelions} from 'common-build/games/Dandelions'
import {GameEntry} from '../GameEntries'

export const DandelionsEntry: GameEntry = {
  canonicalName: "dandelions",
  displayName: 'Dandelions',
  tagline: 'The ancient rivalry of wind and flower.',
  image: dandelionsPreviewImg,
  GameClass: Dandelions,
  instructions: DandelionsInstructions.getInstructionCards(),
  Canvas: DandelionsCanvas
}
