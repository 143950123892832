import {UnknownGame} from '../../common-build/stuff/Game'

export class BaseGameManager {
  game: UnknownGame | null = null
  changeHandler: ((game: UnknownGame | null) => void) | null = null

  setChangeHandler(fn) {
    this.changeHandler = fn
  }

  onChanged() {
    this.changeHandler?.(this.game)
    // console.log('broadcasting game change')
  }

}
