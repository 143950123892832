import './LAPCanvas.scss'
import React, {useEffect, useState} from 'react'
import {Button, ToggleButton} from '@mui/material'
import magnifyingGlass from '../../images/magnifyingglass.png'
import '../../components/chrome/InfoDialog.scss'
import {Copyable2} from "../../components/chrome/Copyable2";
import {LAP} from 'common-build/games/LAP'
import {CountDown} from "../../components/CountDown";
import {useLAPTracker} from './LAPTracker'
import useUndo from 'use-undo'
import {Cell, ClickMode, LAPGrid} from './LAPGrid'
import {countColors} from './utils'

interface Probe {
  row: number;
  col: number;
  results: number[];
}

interface LAPCanvasProps {
  game: LAP;
  puzzleNumber?: number;
  nextPuzzleDate?: Date;
  subtitle?: string;
  initialProbes?: { row: number, col: number }[];
}

export function LAPCanvas(props: LAPCanvasProps) {
  const initialProbes: Probe[] = (props.initialProbes || []).map(p => ({...p, results: props.game.probe(p.row, p.col)}))

  const {nextPuzzleDate} = props
  const {height, width, colorTargets} = props.game
  const probe = (row: number, col: number) => props.game.probe(row, col)
  const submit = (grid: number[][]) => props.game.submit(grid)

  const [isShaking, setIsShaking] = useState<boolean>(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false)
  const [isSolved, setIsSolved] = useState<boolean>(false)
  const [probes, setProbes] = React.useState<Probe[]>(initialProbes)
  const [currentProbe, setCurrentProbe] = React.useState<Probe>()
  const [clickMode, setClickMode] = React.useState<ClickMode>({ probe: true})
  const [strikes, setStrikes] = React.useState<number>(0)

  const didProbe = Array(height).fill(0).map(_ => Array(width).fill(false))
  for (const probe of probes) {
    didProbe[probe.row][probe.col] = true
  }

  const forcedProbe = clickMode.probe && clickMode.forcedProbe

  const initialGrid = Array(height).fill(0).map(_ => Array(width).fill(0))
  const [
    {present: grid},
    gridActions
  ] = useUndo<number[][]>(initialGrid)
  const tracker = useLAPTracker(props.puzzleNumber, props.subtitle)

  function attemptSetClickMode(mode: ClickMode) {
    if (isSolved) return
    if (forcedProbe) return
    setClickMode(mode)
  }

  function colorClicked(i: number) {
    attemptSetClickMode({color: i+1})
  }

  function renderIntersectionOverlay(row: number, col: number) {
    if (row === 0 || col === 0) return null
    const probe = probes.find(p => p.row === row && p.col === col)
    if (forcedProbe && (forcedProbe.row !== row || forcedProbe.col !== col)) return null

    return <div className='probe'
                onMouseOver={() => setCurrentProbe(probe)}
                onMouseOut={() => setCurrentProbe(undefined)}
                onClick={() => onIntersectionClicked(row, col)}>
      {
        probe ?
          <img src={magnifyingGlass} style={{height: '30px', width: '30px'}}/> :
          (clickMode.probe ? <img src={magnifyingGlass} style={{height: '15px', width: '15px'}}/> : null)
      }
    </div>
  }

  function onIntersectionClicked(row: number, col: number) {
    if (didProbe[row][col]) return
    tracker.trackStartIfNecessary()
    addProbe(row, col)
    setClickMode({probe: true})
  }

  function addProbe(row: number, col: number) {
    const observations = probe(row, col)
    const newProbe = {row, col, results: observations}
    const newProbes = [...probes, newProbe]
    setProbes(newProbes)
    setCurrentProbe(newProbe)
  }

  function ProbeResult(props: { result?: number[] }) {
    return <div className='probe-result'>
      {Array(4).fill(0).map((_, i) =>
        <div className='probe-result-cell' key={i}>
          <Cell color={props.result?.[i] || 0} />
        </div>
      )}
    </div>
  }

  function onSubmit() {
    if (isSolved) return
    const response = submit(grid)
    if (response.correct) {
      setIsSolved(true)
      tracker.trackComplete(probes.length, strikes)
      return
    }
    setStrikes(strikes + 1)
    const {newProbe} = response
    if (newProbe && !probes.find(p => p.row === newProbe.row && p.col === newProbe.col)) {
      setClickMode({probe: true, forcedProbe: newProbe})
    }
    setIsShaking(true)
    setIsSubmitDisabled(true)
    setTimeout(() => setIsShaking(false), 1000)
    setTimeout(() => setIsSubmitDisabled(false), 3000)
  }

  const currentColorCounts = countColors(colorTargets.length, grid)
  let submitReady = true

  if (currentColorCounts[0] !== 0) submitReady = false
  for (let i=1; i<colorTargets.length; i++) {
    if (currentColorCounts[i] !== colorTargets[i-1]) submitReady = false
  }

  function buildVictorySummary() {
    const puzzleNumber = props.puzzleNumber
    return '```L.A.P.' + (puzzleNumber ? ` #${puzzleNumber}${props.subtitle ? `(${props.subtitle})` : ''}` : '') + '\n' +
        probes.length + ' probes' + (strikes ? ' + ' + strikes + ' strike(s)' : '') + '\n' +
        '┏' + Array(width - 1).fill(0).map(_ => '━┯').join('') + '━┓\n' +
        grid.slice(1).map((row, i) => '┠' +
            row.map((cell, j) =>
                (didProbe[i + 1][j + 1] || didProbe[i + 1][j] ? '═' : '─') +
                (didProbe[i + 1][j + 1] ? '╬' :
                    (j === width - 1 ? '┨' : '┼'))
            ).join('')
        ).join('\n') + '\n' +
        '┗' + Array(width - 1).fill(0).map(_ => '━┷').join('') + '━┛```'
  }
    // 'L.A.P. #4\n' +
    // '3 probes\n' +
    // '┌─┬─┬─┬─┬─┐\n' +
    // '├─┼═╬═┼─┼─┤\n' +
    // '├─┼─┼═╬═┼─┤\n' +
    // '├═╬═┼─┼─┼─┤\n' +
    // '└─┴─┴─┴─┴─┘'


  return <>
    <div className='GamePlay page'>
      <div className='section subtitle'>
        <div className='LAPCanvas'>
          <LAPGrid
            grid={grid}
            gridActions={gridActions}
            colorTargets={colorTargets}
            clickMode={clickMode}
            isSolved={isSolved}
            currentProbe={currentProbe}
            onColorClicked={colorClicked}
            renderIntersectionOverlay={renderIntersectionOverlay}
            />
          <div className='probe-section'>
            <div style={{minHeight: '10px'}}></div>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <ToggleButton className='probe-button' disabled={!!forcedProbe} value="check" selected={clickMode.probe} onClick={() => attemptSetClickMode({probe: true})}>
                Probe <img className='probe-image' src={magnifyingGlass} style={{height: '15px', width: '15px'}}/>
              </ToggleButton>
              <ProbeResult result={currentProbe?.results} />
            </div>

            Probes used: {probes.length}
            {strikes ? <div className='strikes'>Strikes: {strikes}</div> : null}
            {submitReady && !isSolved ? <div className='submit'>
                <Button className={isShaking ? ' shaking' : ''}
                        variant="contained"
                        disabled={isSubmitDisabled || !!forcedProbe}
                        onClick={() => onSubmit()}>
                  Submit
                </Button>
              </div>
              : null}
            { isSolved ? <div className='victory' style={{position: 'relative'}}>
              <Copyable2 textToCopy={buildVictorySummary()} normalButtonText='Victory!' copiedButtonText='Copied to clipboard!'/>
              { nextPuzzleDate && <span> (Next puzzle in: <CountDown target={nextPuzzleDate} />)</span> }
            </div> : null }
          </div>
        </div>
      </div>
    </div>
  </>

}

export function Shakeable(props: { children: React.ReactNode }) {
  const [isShaking, setIsShaking] = useState<boolean>(false)
  return <div className={'Shakeable' + (isShaking ? ' shaking' : '')}
              onAnimationEnd={() => setIsShaking(false)}>
    <button onClick={() => setIsShaking(true)}>Shake</button>
  </div>
}
