import '../boring-page.scss'
import image1 from './images/image1.jpg'
import image2 from './images/image2.png'
import image3 from './images/image3.png'

export function About() {
    return <div className='boring-page'>
        <p>Welcome, friend, to what is arguably the best site on the internet.</p>
        <p>My name is Ben Orlin. I wrote the book <em><a href='/buy-the-book'>Math Games with Bad Drawings</a></em>.</p>
        <img src={image1} alt="math-kit" />

        <p>But I did not make this site! Such wonders are far beyond me. The work was begun by Adam Bildersee, and then brought to glorious
            fruition by Chris St. John (<a href='https://www.buymeacoffee.com/csjsoftware' target='_blank'>whom you can tip</a> for his marvelous service).
        </p>
        <p>Here, you can play games from the book, in three basic modes:</p>
        <ol>
            <li><b>Local multiplayer</b>. The default for most games. Just pull a friend to your side and start clicking away!</li>
            <li>
                <p><b>Remote multiplayer</b>. You can play with anyone, anywhere! (As long as they want to play with you, that is.
                    As James Carse said: “He who <em>must</em> play cannot <em>play</em>.”)</p>
                <p>Just click this button:</p>
                <img src={image2} alt="remote-multiplayer" />
                <p>Then switch from “Local” to “Party!”:</p>
                <img src={image3} alt="party-mode" />
                <p>Then you can copy the URL, send it to your pal, and each claim one of the “seats” at the game by clicking “Sit.”</p>
            </li>
            <li><p><b>Solo puzzle</b>. At the moment the only true solo puzzle is <a href='/games/LAP'>our crown jewel, LAP</a>.
                But I recommend using the “local multiplayer” mode for your own solo adventures, such as…</p>
                <ul>
                    <li>In <a href='/games/paper-boxing'>Paper Boxing</a>,
                        what is the maximum number of rounds that you can make a single player win?</li>
                    <li>In <a href='/games/turning-points'>Turning Points</a>,
                        how many turns can you achieve in a single cascade?</li>
                    <li>In <a href='/games/prophecies'>Prophecies</a>,
                        how many X’s can you make appear without placing any yourself?</li>
                </ul>
            </li>
        </ol>
        <p>Our <a href='https://discord.gg/6hqXAgthwT' target='_blank'>Discord</a> is always open for feedback and chatter.
            Chris and I would love to hear what you’re thinking! In the meantime, play on, my friend.</p>

        <p>With affection,</p>
        <p>Ben</p>
    </div>
}