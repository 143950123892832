import './NeighborsCanvas.scss';
import Die from '../../components/game/Die';
import Grid from '../../components/game/Grid';
import PlayerHelper from '../../players/PlayerHelper';
import React, {ReactElement, useEffect, useState} from 'react';
import {Neighbors, NeighborsAction, PublicNeighborsGameState} from '../../common-build/games/Neighbors'
import {GamePhase} from "../../common-build/stuff/GamePhase";
import {CanvasProps} from '../../common-build/stuff/CanvasProps'
import {Tabs} from "../../components/game/Tabs";

const NeighborsCanvas = (props: CanvasProps<Neighbors, NeighborsAction>) => {
  const { game, isLocal } = props;
  const gamePhase = game.gamePhase;
  const gameStateAny: any = game.gameState
  const gameState: PublicNeighborsGameState = gameStateAny  // TODO: type hacks
  const gameSettings = game.gameSettings;
  const players = gameSettings.players;
  const grids = gameState.grids;
  const numRows = grids[0].length;
  const numCols = grids[0][0].length;
  const [pendingMoves, setPendingMoves] = useState({});

  useEffect(() => {
    if (gameState.lastMove && gameState.lastMove.die) {
      setPendingMoves({});
    }
    if (gamePhase !== GamePhase.PLAYING) {
      setPendingMoves({});
    }
  }, [gameState, gamePhase]);

  const onAction = value => {
    props.onChooseMove(value);
  };

  interface Chain {
    rowFrom?: number;
    rowTo?: number;
    colFrom?: number;
    colTo?: number;
    value: number | null;
    size: number;
    key?: string;
  }

  // TODO: Refactor this to call into Neighbors
  const circlesData = (() => {
    let circlesData: Chain[][] = [];
    for (let i = 0; i < players.length; i++) {
      let circleData: Chain[] = [];
      let grid = grids[i];
      for (let row = 0; row < numRows; row++) {
        let chain : Chain = { rowFrom: row, rowTo: row, value: null, size: 0 };
        for (let col = 0; col < numCols; col++) {
          let value = grid[row][col]
          if (value && value === chain.value) {
            Object.assign(chain, {colTo: col, size: chain.size + 1});
          } else {
            if (chain.size > 1) {
              circleData.push({...chain, key: `r-${row}-${chain.value}`});
            }
            Object.assign(chain, {colFrom: col, value, size: 1});
          }
        }
        if (chain.size > 1) {
          circleData.push({...chain, key: `r-${row}-${chain.value}`});
        }
      }
      for (let col = 0; col < numCols; col++) {
        let chain = { colFrom: col, colTo: col, value: null, size: 0 };
        for (let row = 0; row < numRows; row++) {
          let value = grid[row][col];
          if (value && value === chain.value) {
            Object.assign(chain, {rowTo: row, size: chain.size + 1});
          } else {
            if (chain.size > 1) {
              circleData.push({...chain, key: `c-${col}-${chain.value}`});
            }
            Object.assign(chain, {rowFrom: row, value, size: 1});
          }
        }
        if (chain.size > 1) {
          circleData.push({...chain, key: `c-${col}-${chain.value}`});
        }
      }
      circlesData.push(circleData);
    }
    return circlesData;
  })();

  const isSquareTouchable = (playerIndex, data) => {
    return (
      gamePhase === GamePhase.PLAYING &&
      gameState.die.rolled &&
      !(data && data.value) &&
      (isLocal || PlayerHelper.isOwnedByMe(players[playerIndex]) || PlayerHelper.isUnowned(players[playerIndex])) &&
      !pendingMoves[playerIndex]
    );
  };

  const isSquareHighlighted = (playerIndex, data, row, col) => {
    if (gamePhase === GamePhase.PLAYING &&
        gameState.lastMove?.players) {
      let highlightedSquare = gameState.lastMove.players[playerIndex];
      return highlightedSquare.row === row && highlightedSquare.col === col;
    }

    if (gamePhase === GamePhase.PLAYING &&
        gameState.lastMove?.die) {
      let highlightedSquare = pendingMoves[playerIndex] || {};
      return highlightedSquare.row === row && highlightedSquare.col === col;
    }
    return false;
  };


  const onSquareTouch = (playerIndex, data, row, col) => {
    if (!isSquareTouchable(playerIndex, data)) {
      return;
    }
    onAction({ playerIndex, row, col });
    setPendingMoves(
        Object.assign({}, pendingMoves, {[playerIndex]: {row, col}}));
  };

  const rollDie = () => {
    if (gamePhase !== GamePhase.PLAYING || gameState.die.rolled) {
      return;
    }
    onAction({ roll: true });
  };

  const renderCircle = (circleData, i) => {
    let left = `${100 * circleData.colFrom / numCols}%`;
    let right = `${100 * (numCols - circleData.colTo - 1) / numCols}%`;
    let topPx = `${100 * circleData.rowFrom / numRows}%`;
    let bottom = `${100 * (numRows - circleData.rowTo - 1) / numRows}%`;
    return (
      <div
          className='circle' key={circleData.key}
          style={{top: topPx, left, right, bottom}} />
    );
  };

  const transformGrid = (grid: number[][], playerIndex) => {
    return grid.map(row =>
      row.map(value => {
        if (value === 0) return null
        return ({value})
      })
    )
  }

  const renderGrid = (player, playerIndex) => {
    return (
        <Grid
            className='grid'
            grid={transformGrid(gameState.grids[playerIndex], playerIndex)}
            squareStyle={() => PlayerHelper.getStyleClass(player)}
            isTouchable={(data) => isSquareTouchable(playerIndex, data)}
            isHighlighted={
                (data, i, j) => isSquareHighlighted(playerIndex, data, i, j)}
            onTouch={(data, i, j) => onSquareTouch(playerIndex, data, i, j)}
            hashMaterial={[playerIndex]}
            >
          <div className='circles'>
            {circlesData[playerIndex].map(renderCircle)}
          </div>
        </Grid>
    );
  };

  return (
    <div className='NeighborsCanvas'>
      <Tabs players={players} renderTabContent={renderGrid} additionalTabStuff={
        <div className='die'>
          <Die
              sides={10}
              value={gameState.die.value}
              rolled={gamePhase !== GamePhase.PLAYING || gameState.die.rolled}
              onTouch={rollDie} />
        </div>
      } />
    </div>
  );
};

export default NeighborsCanvas;
