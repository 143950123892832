import {ClickMode, LAPGrid} from './LAPGrid'
import useUndo from 'use-undo'
import React, {useState} from 'react'
import './LAPCanvas.scss'
import {countColors} from './utils'
import {Button, Stack, Switch, TextField, ToggleButton, Typography} from '@mui/material'
import {generateExternalLAPBoard} from '../../common-build/tools/LAPGenerator'
import {InputNumber} from 'primereact/inputnumber'
import magnifyingGlass from '../../images/magnifyingglass.png'
import {LAPCanvas} from './LAPCanvas'
import {LAP} from '../../common-build/games/LAP'

export function LAPDesigner() {
  const [width, setWidth] = useState<number>(6)
  const [height, setHeight] = useState<number>(6)
  const [numColors, setNumColors] = useState<number>(4)
  const [probes, setProbes] = useState<{row: number, col: number}[]>([])
  const [showGrid, setShowGrid] = useState<boolean>(true)

  const emptyGrid = Array(height).fill(0).map(_ => Array(width).fill(0))
  const targets = Array(numColors).fill(0)

  const [{present: grid}, gridActions] = useUndo<number[][]>(emptyGrid)
  const [clickMode, setClickMode] = useState<ClickMode>({color: 1})

  const obj = {
    grid: grid,
    initialProbes: probes,
  }
  const gridIsFull = grid.every(row => row.every(cell => cell !== 0))

  const objString = JSON.stringify(obj)
  const objStringBase64 = btoa(objString)

  function load(newBase64: string) {
    try {
      const newString = atob(newBase64)
      const newObj = JSON.parse(newString)
      const newGrid = newObj.grid
      const newProbes = newObj.initialProbes

      gridActions.set(newGrid)
      setHeight(newGrid.length)
      setWidth(newGrid[0].length)
      setProbes(newProbes)
      setNumColors(Math.max(...newGrid.map(row => Math.max(...row))))
    } catch (e) {
      console.log(e)
    }
  }

  function Autofill() {
    const newGrid = [...grid.map(row => [...row])]
    const colorCounts = countColors(numColors, grid)
    for (let i=0; i<height; i++) {
      for (let j=0; j<width; j++) {
        if (newGrid[i][j] !== 0) continue
        // find the lowest represented color
        let lowestColor = 1
        for (let k=1; k<=numColors; k++) {
          if (colorCounts[k] < colorCounts[lowestColor]) lowestColor = k
        }
        newGrid[i][j] = lowestColor
        colorCounts[lowestColor]++
      }
    }
    gridActions.set(newGrid)
  }

  function Shuffle() {
    const newGrid = [...grid.map(row => [...row])]
    for (let i=0; i<height; i++) {
      for (let j=0; j<width; j++) {
        const i2 = Math.floor(Math.random() * height)
        const j2 = Math.floor(Math.random() * width)
        const temp = newGrid[i][j]
        newGrid[i][j] = newGrid[i2][j2]
        newGrid[i2][j2] = temp
      }
    }
    gridActions.set(newGrid)
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'z') gridActions.undo()
    if (event.key === 'y') gridActions.redo()
  }

  function AutoGlom() {
    generateExternalLAPBoard(grid, async (newGrid) => {
      gridActions.set(newGrid)
      await new Promise(resolve => setTimeout(resolve, 20))
    })
  }

  function reset(newHeight: number, newWidth: number, newNumColors: number) {
    const newGrid = Array(newHeight).fill(0).map(_ => Array(newWidth).fill(0))
    gridActions.set(newGrid)
    setHeight(newHeight)
    setWidth(newWidth)
    setNumColors(newNumColors)
  }

  function renderIntersectionOverlay(row: number, col: number) {
    if (row === 0 || col === 0) return null
    const probe = probes.find(p => p.row === row && p.col === col)

    return <div className='probe'
                onClick={() => onIntersectionClicked(row, col)}>
      {
        probe ?
          <img src={magnifyingGlass} style={{height: '30px', width: '30px'}}/> :
          (clickMode.probe ? <img src={magnifyingGlass} style={{height: '15px', width: '15px'}}/> : null)
      }
    </div>
  }

  function onIntersectionClicked(row: number, col: number) {
    // toggle probe, if present
    const newProbes = [...probes]
    const index = newProbes.findIndex(p => p.row === row && p.col === col)
    if (index !== -1) {
      newProbes.splice(index, 1)
    } else {
      newProbes.push({row, col})
    }
    setProbes(newProbes)
  }

  return <div className={'LAPCanvas'}>
    <h1 style={{fontFamily: 'MWBD'}}>LAP Puzzle Editor</h1>
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div className={'commands'}>
        <div className={'command'}>
          <div>Height</div>
          <InputNumber value={height} min={3} max={10} showButtons buttonLayout={'vertical'} style={{width: '3em'}}
                       onChange={e => reset(e.value || 3, width, numColors)} />
        </div>
        <div className={'command'}>
          <div>Width</div>
          <InputNumber value={width} min={3} max={10} showButtons buttonLayout={'vertical'} style={{width: '3em'}}
                       onChange={e => reset(height, e.value || 3, numColors)} />
        </div>
        <div className={'command'}>
          <div>Colours</div>
          <InputNumber value={numColors} min={2} max={4} showButtons buttonLayout={'vertical'} style={{width: '3em'}}
                       onChange={e => reset(height, width, e.value || 2)} />
        </div>

      </div>
      <div className={'LAPCanvas'} onKeyDown={onKeyDown}>
        <LAPGrid
          grid={ showGrid ? grid : emptyGrid } gridActions={gridActions}
          colorTargets={targets}
          clickMode={clickMode}
          onColorClicked={(color) => setClickMode({color: color+1})}
          renderIntersectionOverlay={renderIntersectionOverlay}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Hide</Typography>
          <Switch checked={showGrid} onChange={() => setShowGrid(!showGrid)} />
          <Typography>Show</Typography>
        </Stack>
        <div className={'commands'}>
          <Button className={'command'} variant={'contained'} onClick={() => gridActions.set(emptyGrid)}>Clear</Button>
          <Button className={'command'} variant={'contained'} onClick={() => Autofill()}>Autofill</Button>
          <Button className={'command'} variant={'contained'} onClick={() => Shuffle()}>Shuffle</Button>
          <Button className={'command'} variant={'contained'} onClick={() => AutoGlom()}>AutoGlom</Button>
          <ToggleButton className='probe-button' value="check" selected={clickMode.probe} onClick={() => setClickMode({probe: true})}>
            Probe <img className='probe-image' src={magnifyingGlass} style={{height: '15px', width: '15px'}}/>
          </ToggleButton>
        </div>
        <TextField style={{width: '500px', fontSize: '8pt'}} multiline value={objStringBase64} onChange={(e) => {
          load(e.currentTarget.value)
          setShowGrid(false)
        }} />
      </div>
      { gridIsFull && <div>
        <LAPCanvas game={new LAP(grid)} initialProbes={probes} key={objStringBase64}/>
      </div> }
    </div>
  </div>


}
