import React from 'react';
import './index.css';
import App from 'components/app/App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {reactPlugin} from './appInsights'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {LAPDesigner} from './games/lap/LAPDesigner'
import 'primeflex/primeflex.scss';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/mdc-light-indigo/theme.css';
import {DirectLAPCanvas} from './games/lap/LAPPage'

const container =  document.getElementById('root')
if (!container) throw new Error('No root element')

const root = createRoot(container);
root.render(
    <GoogleOAuthProvider clientId="607568586368-m25ivmd0sc5s0a75rj1a4flnjr7vmj1q.apps.googleusercontent.com">
      <AppInsightsContext.Provider value={reactPlugin}>
        <React.StrictMode>
            <App />
          {/*<LAPDesigner />*/}
          {/*<DirectLAPCanvas />*/}
        </React.StrictMode>
      </AppInsightsContext.Provider>
    </GoogleOAuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
