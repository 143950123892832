import './Footer.scss';
import React from 'react';
import Coffee from '../Coffee'

function Footer() {
  return <div className="Footer">
    <div className="top"/>
    <div className="content">
      &copy; 2023 Math with Bad Drawings
      <Coffee/>
    </div>
  </div>
}

export default Footer;

