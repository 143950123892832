import neighborsPreviewImg from '../../images/neighbors-preview.png'
import {Neighbors} from '../../common-build/games/Neighbors'
import NeighborsInstructions from './NeighborsInstructions'
import NeighborsCanvas from './NeighborsCanvas'

export const NeighborsEntry = {
  canonicalName: "neighbors",
  displayName: 'Neighbors',
  tagline: 'A numerical make-your-own sundae bar.',
  image: neighborsPreviewImg,
  GameClass: Neighbors,
  instructions: NeighborsInstructions.getInstructionCards(),
  Canvas: NeighborsCanvas
}
