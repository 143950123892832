import React, {useEffect, useState} from "react";
import {httpGet} from "../../components/http/Http";
import {LAP, LAPPuzzle} from "../../common-build/games/LAP";
import {SubPuzzle} from "../../../api/src/common/games/LAP";
import GameInstructionsDialog from "../../components/game/GameInstructionsDialog";
import {LAPEntry} from "./LAPEntry";
import {Tab, Tabs} from "@mui/material";
import {LAPCanvas} from "./LAPCanvas";
import {useParams} from "react-router";
import {LoadOtherLAP} from "./LoadOtherLAP";

interface UIPuzzle {
    lap: LAP;
    initialProbes?: { row: number, col: number }[];
    title?: string;
    author?: string;
}

export function LAPPage() {
    const {extra} = useParams<{ extra?: string }>()
    const [puzzles, setPuzzles] = useState<UIPuzzle[]>()
    const [puzzleNumber, setPuzzleNumber] = useState<number>(extra ? parseInt(extra) : 0)
    const [nextPuzzleDate, setNextPuzzleDate] = useState<Date>()
    const [page, setPage] = useState<number>(-1)
    const [author, setAuthor] = useState<string>()

    const backupGrid = [
        [3, 3, 3, 4, 4, 4],
        [3, 2, 3, 4, 2, 4],
        [3, 2, 2, 2, 2, 4],
        [3, 3, 1, 1, 2, 4],
        [3, 1, 1, 2, 2, 4],
        [1, 1, 1, 1, 1, 4]
    ]

    useEffect(() => {
        if (puzzleNumber) {
           request('/puzzles/lap/' + puzzleNumber)
           return
        }
        request('/puzzles/lap/')
        // setPuzzles([{lap: new LAP(generateLAPBoard(8,6,4))}])
    }, [puzzleNumber])

    function request(urlFragment: string) {
        httpGet(urlFragment).then(response => {
            const puzzle: LAPPuzzle = response.body
            setPuzzleNumber(puzzle.puzzleNumber)
            setNextPuzzleDate(puzzle.nextPuzzleDate ? new Date(puzzle.nextPuzzleDate) : undefined)
            setAuthor(puzzle.author)
            if (puzzle.grid) {
                setPuzzles([{lap: new LAP(puzzle.grid)}])
                return
            } else if (puzzle.subpuzzles) {
                setPuzzles(puzzle.subpuzzles.map((sub: SubPuzzle) => ({
                    lap: new LAP(sub.grid),
                    title: sub.title,
                    initialProbes: sub.initialProbes
                })))
                return
            } else {
                setPuzzleNumber(-1)
                setPuzzles([{lap: new LAP(backupGrid)}])
            }
        }, error => {
            console.log(error)
            setPuzzleNumber(-1)
            setPuzzles([{lap: new LAP(backupGrid)}])
        })

    }

    if (!puzzles) {
        return <div>Loading...</div>
    }

    return <div>
        <div style={{textAlign: 'center', fontFamily: 'MWBD'}}>
            L.A.P. #{puzzleNumber} {author && <span>by <b>{author}</b></span>}
            <div style={{width: '20px', display: 'inline-block'}}/>
            <GameInstructionsDialog open={true} entry={LAPEntry}/>
            <LoadOtherLAP currentPuzzleNumber={puzzleNumber || 0}/>
        </div>
        {
            puzzles.length === 1 &&
            <LAPCanvas initialProbes={puzzles[0].initialProbes} game={puzzles[0].lap} puzzleNumber={puzzleNumber} nextPuzzleDate={nextPuzzleDate}/> || <>
                <Tabs centered value={page} onChange={(_, newVal) => setPage(newVal)}>
                    {page === -1 && <Tab label={'Select:'} key={-1} value={-1} style={{fontFamily: 'MWBD'}}/>}
                    {puzzles.map((puzzle, i) =>
                        <Tab label={puzzle.title} key={i} value={i} style={{fontFamily: 'MWBD'}}/>
                    )}
                </Tabs>
                {puzzles.map((puzzle, i) =>
                    <div key={i} hidden={i !== page}>
                        <LAPCanvas initialProbes={puzzle.initialProbes} game={puzzle.lap} subtitle={puzzle.title} puzzleNumber={puzzleNumber}
                                   nextPuzzleDate={nextPuzzleDate}/>
                    </div>
                )}
            </>}
    </div>
}

export function DirectLAPCanvas() {
    const grid = [
        [1,1,2,3],
        [1,2,2,3],
        [1,2,3,3],
    ]

    return <LAPCanvas game={new LAP(grid)} initialProbes={[{row:1, col:2}]}/>
}
