import {useState} from "react";
import copy from "copy-text-to-clipboard";
import {Button} from "@mui/material";

interface Copyable2Props {
    textToCopy: string;
    normalButtonText: string;
    copiedButtonText: string;
}

export function Copyable2(props: Copyable2Props) {
    const [copied, setCopied] = useState(false);

    const doCopy = () => {
        setCopied(true);
        copy(props.textToCopy)
        setTimeout(() => setCopied(false), 3000);
    }

    return <Button variant='contained' onClick={doCopy}>{copied ? props.copiedButtonText : props.normalButtonText}</Button>
}