import {useEffect, useState} from 'react'
import {UnknownGame} from '../../common-build/stuff/Game'
import {_memoryInstance, GameManager} from './GameManager'
import {PlayMode} from '../../page/GamePlay'
import {_httpInstance, HttpGameManager} from './HttpGameManager'

export function useGameManager(canonicalName: string, gameKey: string | null) {
  const [refresh, setRefresh] = useState({})
  const [game, setGame] = useState<UnknownGame | null>(null)
  const [playMode, setPlayMode] = useState<PlayMode>(gameKey ? 'remote' : 'local')

  const gameManager = playMode === 'remote' ? _httpInstance : _memoryInstance;
  const undo = playMode === 'local' ? () => _memoryInstance.undo() : null;

  useEffect(() => {
    gameManager.setChangeHandler((g) => {
      setGame(g)
      setRefresh({}) // force re-render, whether game is changed or not -- we should check this
    })
    return () => gameManager.setChangeHandler(null)
  }, [gameManager])

  useEffect(() => {
    if (gameKey) {
      gameManager.joinGame(gameKey)
    } else {
      gameManager.newGame(canonicalName)
    }
  }, [gameKey, gameManager])

  return { gameManager, game, playMode, setPlayMode, undo }
}
