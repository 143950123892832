import {useEffect, useState} from "react";

interface CountDownProps {
    target: Date
}

function calculateTimeLeft(target: Date) {
    const difference = target.getTime() - new Date().getTime()
    let timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    }

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        }
    }

    return timeLeft
}

export function CountDown({ target }: CountDownProps) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(target))

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(target))
        }, 1000)
        return () => clearInterval(timer)
    }, [target])

    function pad(num: number) {
        return num < 10 ? '0' + num : num
    }

    return <>{pad(timeLeft.hours) + ':' + pad(timeLeft.minutes) + ':' + pad(timeLeft.seconds)}</>
}
