import lapPreview from './instructions/media/preview.png'
import LAPInstructions from './LAPInstructions'
import {GameEntry} from '../GameEntries'
import {withRouter} from 'react-router'
import {LAPPage} from "./LAPPage";

export const LAPEntry: GameEntry = {
  canonicalName: "LAP",
  displayName: 'Labyrinthine Area Puzzles (L.A.P.)',
  tagline: 'The game that Battleship aspires to be.',
  image: lapPreview,
  instructions: LAPInstructions.getInstructionCards(),
  CustomElement: withRouter(LAPPage),
}
