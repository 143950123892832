import './InfoDialog.scss';
import React, {useState} from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


/**
 * Displays an icon which, when clicked, brings up a dialog.
 */

interface InfoDialogProps {
  className?: string;
  dialogClassName?: string;
  dialogRef?: any;
  icon?: string;
  header?: string;
  footer?: any;
  footerButtons?: any[];
  content?: any;
  open?: boolean;  // whether to render as initially visible
  onHide?: () => void;
  onShow?: () => void;
  children?: any;
}

function InfoDialog(props: InfoDialogProps) {
  const [visible, setVisible] = useState(props.open);

  function onHide() {
    setVisible(false);
    if (props.onHide) {
      props.onHide();
    }
  }

  function onShow() {
    if (props.onShow) {
      props.onShow();
    }
  }

  function show() {
    setVisible(true)
  }

  function customButtonClick(button) {
    let close = button.close === undefined ? true : button.close;
    if (button.onClick) {
      button.onClick();
    }
    if (close) {
      setVisible(false)
    }
  }

  function renderFooter() {
    if (props.footer) {
      return props.footer;
    }
    if (!props.footerButtons) {
      return null
    }
    return (
      <div className="buttons">
        {props.footerButtons.map(b =>
          <Button
            key={b.label} label={b.label} className={b.className}
            disabled={b.disabled}
            onClick={() => customButtonClick(b)}/>)
        }
      </div>
    )
  }

  return (
    <div className={`InfoDialog ${props.className}`}>
      <i className={`pi ${props.icon || 'pi-info-circle'}`}/>
      <Button
        className='target'
        label=''
        icon='pi'
        onClick={show}
        tooltipOptions={{
          className: 'InfoDialog-tooltip',
        }}/>
      <Dialog
        className={`InfoDialog-element ${props.dialogClassName}`}
        ref={props.dialogRef}
        dismissableMask
        blockScroll
        baseZIndex={9999}
        appendTo={document.body}
        visible={visible}
        header={props.header || 'Information'}
        footer={renderFooter()}
        onHide={onHide}
        onShow={onShow}>
        <div onClick={e => e.stopPropagation()}>
          {visible ? props.content : ''}
        </div>
      </Dialog>
    </div>
  );
}


export default InfoDialog;
