import './GamesList.scss';
import Card from '../components/chrome/Card';
import FlagService from '../services/FlagService';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {GameEntries} from "../games/GameEntries";

const GamesList = _ => {
  const flagService = new FlagService.Factory().create();
  const renderGameCard = entry => {
    if (entry.isWorkInProgress && !flagService.get('showWipGames')) {
      return;
    }
    return (
      <div className='p-col-12 p-md-6 p-lg-4' key={entry.canonicalName}>
        <NavLink to={`/games/${entry.canonicalName}`} className='gameCardWrapper'>
          <Card
              img={entry.image}
              header={entry.displayName}
              text={entry.tagline} />
        </NavLink>
      </div>
    );
  }

  return (
    <div className='GamesList page'>
      <div className='subtitle'>
        Games List
      </div>
      <div className='section'>
        <div className='p-grid'>
          { GameEntries.map(renderGameCard) }
        </div>
      </div>
    </div>
  );
}


export default GamesList;
