import {Dir} from "../../common-build/stuff/Dir";
import React from "react";
import './ConnectingLine.scss';

export const ConnectingLine = (props: {dir: Dir}) => {
    return (
        <div className={`overlay linkLineHolder ${props.dir}`}>
            <div className='linkLine' />
        </div>
    );
};
