import {Profile} from '../../page/AccountPage'
import React, {createContext} from 'react'

export interface ProfileContextType {
  profile: Profile | undefined,
  setProfile: React.Dispatch<React.SetStateAction<Profile | undefined>>
}

export const ProfileContext = createContext<ProfileContextType>({
  profile: undefined,
  setProfile: () => {}
})
