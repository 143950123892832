import propheciesPreviewImg from '../../images/prophecies-preview.png'
import {Prophecies} from '../../common-build/games/Prophecies'
import PropheciesInstructions from './PropheciesInstructions'
import PropheciesCanvas from './PropheciesCanvas'

export const PropheciesEntry = {
  canonicalName: "prophecies",
  displayName: 'Prophecies',
  tagline: 'A game of self-fulfilling (and self-defeating) predictions.',
  image: propheciesPreviewImg,
  GameClass: Prophecies,
  instructions: PropheciesInstructions.getInstructionCards(),
  Canvas: PropheciesCanvas
}
