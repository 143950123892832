import React, {ReactNode, useEffect} from 'react'
import {withRouter} from 'react-router';


// See
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
function ScrollToTop(props: {children: ReactNode[], location: {pathname: string}}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);

  return props.children;
}

export default withRouter(ScrollToTop);
