import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js'
import {useState} from 'react'

export function useLAPTracker(puzzleNumber?: number, puzzleTitle?: string) {
  const appInsights = useAppInsightsContext();
  const [timeStarted, setTimeStarted] = useState<number | undefined>();

  return {
    trackStartIfNecessary: () => {
      if (!puzzleNumber) return
      if (timeStarted) return
      setTimeStarted(Date.now());

      appInsights.trackEvent({ name: 'puzzle-started' }, {
        puzzleType: 'lap',
        puzzleNumber: puzzleNumber.toString(),
        ...puzzleTitle && {puzzleTitle},
      })
      console.log('Tracking: ' + puzzleNumber + puzzleTitle + ' started')
    },

    trackComplete: (probes: number, strikes: number) => {
      if (!puzzleNumber) return
      if (!timeStarted) return

      appInsights.trackEvent({ name: 'puzzle-solved' }, {
        puzzleType: 'lap',
        puzzleNumber: puzzleNumber.toString(),
        ...puzzleTitle && {puzzleTitle},
        probes: probes,
        strikes: strikes,
        secondsElapsed: Math.floor((Date.now() - timeStarted) / 1000),
      });
    },
  }
}
