import image1 from './images/image1.jpg'
import image2 from './images/image2.jpg'
import image3 from './images/image3.jpg'
import '../boring-page.scss'

export function Buy() {
	return <div className="boring-page">
		<h1>HOW CAN I BUY THESE LOVELY GAMES?</h1>
		<p><b>Math Games with Bad Drawings</b> comes in two delightful versions:</p>
		<ol>
			<li>
				<p>A <a target='_blank' href="https://www.hachettebookgroup.com/titles/ben-orlin/math-games-with-bad-drawings/9780762499861/">hardcover compendium</a> (or <a target='_blank' href="https://www.hachettebookgroup.com/titles/ben-orlin/math-games-with-bad-drawings/9780762499854/">ethereal ebook</a>)
					of delights and wonders, featuring 75 ¼ games and deeply researched essays on why they are so cool</p>
				<img src={image1} alt="Hardcover" />
			</li>
			<li>
				<p>A delightfully tidy <a target='_blank' href="https://www.hachettebookgroup.com/titles/ben-orlin/math-games-with-bad-drawings-the-ultimate-game-collection/9780762499878/">game kit</a> with whiteboards, pens, dice, game tokens, and a slender volume sharing the rules to 34 handpicked games</p>
				<img src={image2} alt="Game Kit" />
			</li>
		</ol>
		<h1>WHAT IF I AM NOT YET PERSUADED TO PART WITH MY HARD-EARNED DOLLARS, OR MY EVEN HARDER-EARNED PESOS?</h1>
		<p>Well, perhaps I can persuade you with positive press, such as…</p>
		<ul>
			<li>A <a target='_blank' href="https://arstechnica.com/science/2022/04/channel-your-inner-math-geek-with-this-rich-collection-of-simple-interactive-games/">generous write-up</a> from Jennifer Ouellette at <em>Ars Technica</em>?</li>
			<li>A <a target='_blank' href="https://aperiodical.com/2022/04/review-math-games-with-bad-drawings/">lovely review</a> from leading math site <em>The Aperiodical</em>?</li>
			<li>A <a target='_blank' href="https://mathforlove.com/2022/05/review-math-games-with-bad-drawings/">glowing endorsement</a> from award-winning game designer Dan Finkel?</li>
		</ul>
		<p>
			Or do you want to see material from the book appearing in major media outlets? Then would you be swayed by…
			</p>
		<ul>
			<li><a target='_blank' href="https://arstechnica.com/science/2022/04/try-your-hand-at-quantum-go-fish/">Quantum Go Fish</a> (the world’s hardest finger game) at <em>Ars Technica</em>?</li>
			<li><a target='_blank' href="https://fivethirtyeight.com/features/can-you-be-mediocre-enough-to-win/">Mediocrity</a> (and mediocrity-themed puzzles) at FiveThirtyEight’s <em>The Riddler</em>?</li>
			<li><a target='_blank' href="https://www.theguardian.com/science/2022/apr/18/can-you-solve-it-maths-games-with-bad-drawings">More puzzles and glowing coverage</a> at Alex Bellos’s column in <em>The Guardian</em>?
			</li>
</ul>
		<p>
			Is your heart still not thawed? Well, here’s a photo of my then-two-year-old daughter (to whom the book is dedicated) excitedly receiving a shipment:
		</p>
		<img src={image3} alt="Daughter" />
		<p>
			Okay, that’s the best shot I’ve got. If you still don’t wanna buy the book, just play the games
			and thank Chris and Adam for designing such a cool free site.
		</p>
		<p>
			(Or maybe you’ll buy <a target='_blank' href="https://shepherd.com/best-books/math-books-with-genuinely-good-drawings">some other books I like</a>. I won’t be offended!)
		</p>
	</div>
}
