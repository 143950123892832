import './App.scss';
import 'primeflex/primeflex.scss';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import '../../styles/fonts.scss';
import '../../styles/primereact-hacks.scss';
import '../../styles/media.scss';
import '../../styles/page.scss';
import 'primereact/resources/themes/mdc-light-indigo/theme.css';
import '../../styles/theme.scss';
import logo from '../../images/logo-white-small.png';
import Footer from '../chrome/Footer';
import Landing from '../../page/Landing';
import React, {useEffect} from 'react'
import ScrollToTop from '../chrome/ScrollToTop';
import Sidenav from '../chrome/Sidenav';
import Sitemap from './Sitemap';
import Topnav from '../chrome/Topnav';
import UnknownPage from '../../page/UnknownPage';
import { BrowserRouter } from 'react-router-dom';
import { GrowlElement } from '../chrome/Growl';
import { RedirectElement } from '../chrome/Redirect';
import { Redirect, Route, Switch } from 'react-router';
import {Profile} from "../../page/AccountPage";
import { ProfileContext } from './ProfileContext';
import {httpGet} from '../http/Http'


const makePageRoute = item => {
  let component = item.component;
  if (!component) {
    if (item.children && item.children.length > 0) {
      return <Route
          key={item.label}
          exact path={item.path}
          render={_ => <Redirect to={item.children[0].path} />} />;
    }
    return null;
  }
  const c = {o: component};
  return <Route
      key={item.label}
      exact path={item.path || `/${item.label.toLowerCase()}`}
      render={_ => <c.o {...item.componentProps} />} />;
}


const makeChildPageRoutes = item => {
  return [ (item.children || []).map(makePageRoute) ];
}



export function App() {
  const sitemap = new Sitemap.Factory().create();
  const topnavItems = sitemap.getTopnavItems();

  const [profile, setProfile] = React.useState<Profile>();

  useEffect(() => {
    console.log('Checking API...')
    httpGet('/health/ping').then(response => {
      console.log('API is up!')
    }, error => {
      console.log('API is down!')
    })
  }, [])

  return (
      <div className='App'>
        <ProfileContext.Provider value={{profile, setProfile}}>
          <BrowserRouter>
            <ScrollToTop>
              <RedirectElement />
              <GrowlElement />
              <Topnav links={topnavItems.filter(x => x.label !== 'Join')} logo={logo}>
              </Topnav>
              <div className='_AppBackdrop'>
                <Sidenav />
                <div className='_AppContent'>
                  <Switch>
                    <Route exact path='/' component={Landing} />
                    {topnavItems.map(makePageRoute)}
                    {topnavItems.map(makeChildPageRoutes)}
                    <Route path='*' component={UnknownPage} />
                  </Switch>
                </div>
              </div>
              <Footer />
            </ScrollToTop>
          </BrowserRouter>
        </ProfileContext.Provider>
      </div>
  );
}

export default App;
