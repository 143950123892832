import './GameStatusDisplay.scss';
import PlayerHelper from '../../players/PlayerHelper';
import {GamePhase} from '../../common-build/stuff/GamePhase';
import React from 'react';

interface GameStatusDisplayProps {
  gamePhase: GamePhase | null;
  gameState: any;
  gameSettings: any;
  playerNameTransform: (name: string, playerIndex: number) => string;
}

const GameStatusDisplay = (props: GameStatusDisplayProps) => {
  const {gameState, playerNameTransform} = props;
  let message = <></>;
  let players = [];
  let player: any = null;

  switch (props.gamePhase) {
    case GamePhase.PRE_GAME:
      message = <div>Click <em>Start Game</em> to get going!</div>;
      break;
    case GamePhase.PLAYING:
      if (gameState.activePlayerIndex === null) {
        break;
      }
      players = props.gameSettings.players;
      player = players[gameState.activePlayerIndex];
      const playerName = playerNameTransform(player.name, gameState.activePlayerIndex);

      let isLocal =
        PlayerHelper.isOwnedByMe(player) || PlayerHelper.isUnowned(player);
      message = isLocal ?
        <div><em>{playerName}</em> to play</div> :
        <div>Waiting for <em>{playerName}</em> to play</div>;
      break;
    case GamePhase.POST_GAME:
      players = props.gameSettings.players;
      let gameEnd = gameState.gameEnd;
      if (!gameEnd) {
        message = <div>Game over!</div>;
      } else if (gameEnd.draw) {
        message = <div>It’s a draw! How about another?</div>;
      } else if (Number.isInteger(gameEnd.win)) {
        player = players[gameEnd.win];
        const playerName = playerNameTransform(player.name, gameEnd.win);
        message = (
            <div>
              Wow! <em>{playerName}</em> is the winner! How about another?
            </div>);
      }
      break;
    default:
      break;
  }

  return (
    <div className='GameStatusDisplay'>
      {message}
    </div>
  );
};


export default GameStatusDisplay;
