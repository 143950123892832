import AccountPage from '../../page/AccountPage';
import Environment from '../../page/internal/Environment';
import GameLookup from '../../page/internal/GameLookup';
import GamesList from '../../page/GamesList';
import Icons from '../../page/internal/Icons';
import JoinGame from '../../page/JoinGame';
import Test from '../../page/internal/Test';
import {GameEntries, GameEntry} from "../../games/GameEntries";
import { RoutedGamePlay } from "../../page/GamePlay";
import {Buy} from '../../boring-pages/buy/buy'
import {About} from "../../boring-pages/about/about";
import {LAPDesigner} from '../../games/lap/LAPDesigner'


const makeGamePage = (entry: GameEntry) => {
  if (entry.CustomElement) {
    return {
      label: entry.displayName,
      path: `/games/${entry.canonicalName}/:extra?`,
      component: entry.CustomElement,
    };
  }

  return {
    label: entry.displayName,
    path: `/games/${entry.canonicalName}`,
    component: RoutedGamePlay,
    componentProps: {entry},
  };
};


const NAV_LINKS = Object.freeze([
  {
    label: 'About',
    path: '/about',
    component: About,
  },
  {
    label: 'Games',
    path: '/games',
    component: GamesList,
    sidenavVisible: false,
    children: [
      ...GameEntries.map(entry => {
        return makeGamePage(entry);
      })
    ],
  },
  {
    label: 'Join',
    path: '/join/:gameKey',
    component: JoinGame,
  },
  //{
    //label: 'Account',
    //path: '/account',
    //component: AccountPage,
    //// showIf: _ => new AccountService.Factory().create().isLoggedIn(),
  //},
  {
    label: 'Buy the book',
    path: '/buy-the-book',
    component: Buy
  },
  {
    label: 'Blog',
    path: 'https://mathwithbaddrawings.com',
  },
  {
    label: 'LAP Editor',
    path: '/lap-editor',
    component: LAPDesigner,
    showIf: _ => false,
  },
  {
    label: 'Internal',
    path: '/_i',
    showIf: _ => false,
    children: [
      {
        label: 'Icons',
        path: '/_i/icons',
        component: Icons,
      },
      {
        label: 'Environment',
        path: '/_i/environment',
        component: Environment,
      },
      {
        label: 'Game Lookup',
        path: '/_i/gamelookup',
        component: GameLookup,
      },
      {
        label: 'Test Page',
        path: '/_i/testpage',
        component: Test,
        showIf: _ => false,
      },
    ],
  },
]);


class Sitemap {
  getTopnavItems() {
    return NAV_LINKS;
  }

  getVisibleSidenavItems() {
    const currentTopnavItem = NAV_LINKS.find(
        i => window.location.pathname.match('^' + i.path));
    if (currentTopnavItem) {
      if (currentTopnavItem.sidenavVisible === false) {
        return [];
      }
      return currentTopnavItem.children || [];
    }
    return [];
  }

  /** Returns the next URL within a list of children. */
  getNextUrl() {
    let pathname = window.location.pathname;
    let currentTopnavItem = NAV_LINKS.find(i => pathname.match('^' + i.path));
    let children = currentTopnavItem?.children || [];
    let index = children.findIndex(c => pathname.match('^' + c.path));
    if (index < 0 || index+1 >= children.length) {
      return pathname;
    }
    // @ts-ignore
    return children[index + 1].path;
  }

  static Factory = class {
    create() {
      return _instance;
    }
  }
}

const _instance = new Sitemap();

export default Sitemap;

