import './PlayerArea.scss';
import PlayerHelper from '../../players/PlayerHelper';
import React from 'react';
import {Button} from 'primereact/button'

/**
 * props:
 *   activePlayerIndex
 *   players
 *   gameEnd
 *   onSit
 *   onStand
 *   isLocal
 *   scores
 */

interface PlayerAreaProps {
  activePlayerIndex?: number;
  players?: any[];
  playerNameTransform: (name: string, playerIndex: number) => string;
  gameEnd?: any;
  onSit: (playerIndex: number) => void;
  onStand?: () => void;
  isLocal: boolean;
  scores?: number[];
}

const PlayerArea = (props: PlayerAreaProps) => {
  const players = props.players

  if (!players || players.length < 2) {
    return <div className="PlayerArea"/>
  }
  
  const renderPlayer = (playerIndex, extraProps) => {
    let player = players[playerIndex];
    let avatar = (props.isLocal || !PlayerHelper.isUnowned(player)) ?
      <img
        src={PlayerHelper.getAvatar(player)}
        className={extraProps.isActive ? 'active' : ''}
        alt='avatar'/> :
      <Button severity='secondary' style={{color: 'white'}} onClick={() => props.onSit(playerIndex)}
              label={'Sit'}></Button>;
    // TODO: Stand?

    let scores = props.gameEnd?.scores || props.scores;

    let className = 'player ' +
      (extraProps.className || '') + ' ' +
      PlayerHelper.getStyleClass(player) + ' ' +
      (extraProps.isActive ? ' active ' : ' ');
    const renderedScore = scores ? <div className='score'>{scores[playerIndex]}</div> : null;

    return (
      <div key={playerIndex} className={className}>
        {avatar}
        <div className='name'>{props.playerNameTransform(player.name, playerIndex)}</div>
        {renderedScore}
      </div>
    );
  };

  let classes = 'playersHolder'
  let extraProps = players.map((p, i) => ({
    className: `player${i}`,
    isActive: false,
  }))

  if (props.activePlayerIndex !== undefined) {
    classes += ' playerActive'
    extraProps[props.activePlayerIndex].isActive = true
  } else {
    extraProps.forEach(p => p.className += ' neutral')
  }
  return (
    <div className='PlayerArea'>
      <div className={classes}>
        {players.map((p, i) => renderPlayer(i, extraProps[i]))}
      </div>
    </div>
  );
}


export default PlayerArea;
