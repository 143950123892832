import './GamePlay.scss'
import {Button} from 'primereact/button'
import GameInstructionsDialog from '../components/game/GameInstructionsDialog'
import GameSettingsDialog from '../components/game/GameSettingsDialog'
import GameStatusDisplay from '../components/game/GameStatusDisplay'
import LabelValue from '../components/chrome/LabelValue'
import PlayerArea from '../components/player/PlayerArea'
import PlayerSettingsDialog from '../components/player/PlayerSettingsDialog'
import React from 'react'
import VictoryAnimation from '../components/game/VictoryAnimation'
import {withRouter} from 'react-router'
import {GameEntry, NormalGameEntry} from '../games/GameEntries'
import {GamePhase} from '../common-build/stuff/GamePhase'
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js'
import {useGameManager} from '../services/game-managers/UseGameManager'

interface GamePlayProps {
    entry: NormalGameEntry;
    location?: any;
}

export type PlayMode = 'local' | 'remote'

export const GamePlay = (props: GamePlayProps) => {
    const Canvas = props.entry.Canvas
    const gameKey = props.location?.state?.join
    const canonicalName = props.entry.canonicalName

    const appInsights = useAppInsightsContext();
    const { gameManager, game, playMode, setPlayMode, undo} = useGameManager(canonicalName, gameKey)

    const gameState = game && game.gameState || null
    const gamePhase = game && game.gamePhase || null
    const gameSettings = game && game.gameSettings || undefined
    const gameSettingsConfig = game && game.settingsConfig() || []
    const playerNameTransform = (name: string, playerIndex: number) => {
        if (game && game.playerNameTransform) return game.playerNameTransform(name, playerIndex)
        return name
    }

    const showGameSettings = gameSettingsConfig
        .map(configItem => configItem.canonicalName)
        .filter(canonicalName => !canonicalName.match(':'))
        .length > 0;

    const onStartGame = () => {
        appInsights.trackEvent({ name: 'game-started' }, {
            gameType: canonicalName,
        });
        gameManager.startGame();
    }
    const onEndGame = () => gameManager.setGamePhase(GamePhase.POST_GAME);

    const renderInstructions = () =>
        props.entry.instructions.length &&
            <GameInstructionsDialog open={true} entry={props.entry} /> || null

    const renderPlayerSettings = () => {
        let filteredSettingsConfig = gameSettingsConfig
            .filter(i => i.canonicalName.startsWith('players:'));
        return (
            <PlayerSettingsDialog
                gameType={game && game.canonicalName}
                settings={gameSettings}
                settingsConfig={filteredSettingsConfig}
                readOnly={gamePhase === GamePhase.PLAYING}
                onSettingsChange={s => gameManager.setGameSettings(s)}
                onPlayModeChange={m => setPlayMode(m)}
                playMode={playMode}
                gameManager={gameManager}
            />
        );
    };

    const renderGameSettings = () => {
        if (!showGameSettings || !game) {
            return null
        }

        let gamePhase = game.gamePhase
        let gameSettings = game.gameSettings
        return (
          <GameSettingsDialog
            settingsConfig={gameSettingsConfig}
            settings={gameSettings}
            readOnly={gamePhase === GamePhase.PLAYING}
            onSettingsChange={s => gameManager.setGameSettings(s)}/>
        )
    };

    const renderGameMenuButtons = () => {
        switch (gamePhase) {
            case GamePhase.PRE_GAME:
                return <div>
                    <Button label='Start Game' onClick={onStartGame}/>
                </div>;
            case GamePhase.PLAYING:
                return <div>
                    {undo ? <Button label='Undo' onClick={undo}/> : null}
                    <Button label='Quit Game' onClick={onEndGame}/>
                </div>;
            case GamePhase.POST_GAME:
                return <div>
                    <Button label='Start Game' onClick={onStartGame}/>
                </div>;
            default:
                return null;
        }
    };

    const renderGameMenu = () =>
        <LabelValue
            className='gameMenu'
            label={renderGameMenuButtons()}
            labelClassName='gameMenuButtons'
            value={
                <div>
                    {renderPlayerSettings()}
                    {game ? renderInstructions() : null}
                    {game ? renderGameSettings() : null}
                </div>
            }
            styles={LabelValue.Style.LEFT_RIGHT} />;

    const renderLoading = () => <div className='section'>{renderGameMenu()}</div>;


    const renderLoaded = () => {

        return <div className='section'>
            {renderGameMenu()}
            <div className='gameArea'>
                <div className='gameCanvas'>
                    {game && <Canvas
                      onChooseMove={move => gameManager.executeAction(move)}
                      game={game}
                      isLocal={playMode === 'local'}
                    />}
                </div>
                {gamePhase === GamePhase.POST_GAME && gameState && gameState.gameEnd && gameSettings ?
                  <VictoryAnimation
                    gameEnd={gameState.gameEnd}
                    players={gameSettings.players}/> : null}
            </div>
            <GameStatusDisplay {...{gameState, gameSettings, gamePhase, playerNameTransform}} />
            { gameState && <PlayerArea
                  isLocal={playMode === 'local'}
                  players={gameSettings && gameSettings.players}
                  playerNameTransform={playerNameTransform}
                  activePlayerIndex={
                      (gamePhase === GamePhase.PLAYING && gameState.activePlayerIndex) || undefined}
                  gameEnd={gameState.gameEnd}
                  onSit={(playerIndex) => gameManager.sit(playerIndex)}
                  onStand={() => gameManager.stand()}
                  scores={game?.buildScores()}
              />
            }
        </div>;
    }

    const render = () => {
        return (
            <div className='GamePlay page'>
                <div className='section subtitle'>
                    {gameManager.isReady() ? props.entry.displayName : 'Loading…'}
                </div>
                {gameManager.isReady() ? renderLoaded() : renderLoading()}
            </div>
        );
    };

    return render();
}

export const RoutedGamePlay = withRouter(GamePlay)
