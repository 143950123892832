import image1 from './instructions/media/image1.png';
import image2 from './instructions/media/image2.png';
import image3 from './instructions/media/image3.png';
import image4 from './instructions/media/image4.png';
import {LAPCanvas} from './LAPCanvas'
import {LAP} from '../../common-build/games/LAP'
import {generateLAPBoard} from '../../common-build/tools/LAPGenerator'

let lap: LAP | undefined = undefined
generateLAPBoard(4, 4, 4).then(board => {
  lap = new LAP(board)
})

const instructionCards = [
  {
    img: image1,
    content: <div>
      <p>Your task: figure out a <b>secret map</b> made up of <b>colored regions</b>.</p>
      <p>Each region is well-connected (and diagonal connections don't count).</p>
    </div>
  },
  {
    img: image2,
    content: <div>
      <p>Your tool: you may <b>probe clusters of four squares</b>.</p>
      <p>This will tell you, for that cluster, <b>how many squares there are of each color — but <em>not</em> where the specific colors belong</b>.</p>
    </div>
  },
  {
    img: image3,
    content: <div>
      <p>As you go, <b>fill in squares</b> whose colors you have deduced.</p>
      <p>Take advantage of the fact that each region is well-connected!</p>
    </div>
  },
  {
    img: image4,
    content: <div>
      <p>Try to use <b>as few probes as you can.</b> When you've figured out the map, hit "submit".</p>
      <p>There will be a new map to solve every day!</p>
    </div>
  },
  {
    content: <div>
      <p>Try the following tiny grid:</p>
      { lap && <LAPCanvas game={lap} /> }
    </div>
  },
]


const LAPInstructions = {
  getInstructionCards: () => instructionCards
}

export default LAPInstructions;
