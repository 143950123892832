import './Card.scss';
import { Button } from 'primereact/button';
import React from 'react';


const wrap = (cls, e) => (
  <div className={cls}>{e}</div>
);


/**
 * Renders a card in the UI.
 *
 * Props
 *   header
 *   text
 *   className
 *   direction ('vertical' or 'horizontal')
 *   img
 *   imgClassName
 *   imgPadded
 *   buttonPrimaryLabel
 *   buttonPrimaryAction
 *   children
 *   childrenPadded
 */

interface CardProps {
  header?: string;
  text?: string;
  className?: string;
  direction?: string;
  img?: string;
  imgClassName?: string;
  imgPadded?: boolean;
  buttonPrimaryLabel?: string;
  buttonPrimaryAction?: () => void;
  children?: React.ReactNode;
  childrenPadded?: boolean;
  
}
function Card(props: CardProps) {

  function img() {
    if (props.img) {
      const html = (
        <div
          className={'img ' + props.imgClassName}
          style={{backgroundImage: `url(${props.img})`}}/>
      );
      const wrapClasses =
        props.imgClassName + (props.imgPadded ? ' padded' : '');
      return wrap(wrapClasses, html);
    }
    return (
      <div className='noImg'/>
    );
  }

  function text() {
    if (props.header || props.text) {
      return wrap('padded', (
        <div>
          <div className='header'>{props.header}</div>
          {props.text ?
            <div className='text'>{props.text}</div> : ''}
        </div>
      ));
    }
    return null;
  }

  function children() {
    let cls = 'children';
    if (props.childrenPadded) {
      cls += ' padded';
    }
    return wrap(cls, props.children);
  }

  function buttons() {
    if (props.buttonPrimaryLabel) {
      return wrap('padded', (
        <div className='_buttons'>
          <Button
            label={props.buttonPrimaryLabel}
            icon='pi pi-chevron-right'
            iconPos='right'
            onClick={props.buttonPrimaryAction}/>
        </div>
      ));
    }
    return null;
  }

  const extraClasses = props.className;
  return (
    <div className={'Card ' + extraClasses}>
      <div className='shadow'/>
      <div className={'card ' + (props.direction || 'vertical')}>
        {img()}
        <div className='content'>
          {text()}
          {children()}
          {buttons()}
        </div>
      </div>
    </div>
  );
}


export default Card;
