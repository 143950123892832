import {
    PaperBoxing,
    PaperBoxingAction,
    PaperBoxingCell,
    PaperBoxingPublicGameState
} from "../../common-build/games/PaperBoxing";
import './PaperBoxingCanvas.scss';
import {CanvasProps} from "../../common-build/stuff/CanvasProps";
import {GamePhase} from "../../common-build/stuff/GamePhase";
import PlayerHelper from "../../players/PlayerHelper";
import React, {useEffect} from 'react'
import Grid from "../../components/game/Grid";
import {Tabs} from "../../components/game/Tabs";
import {ConnectingLine} from "../../components/game/ConnectingLine";

export const PaperBoxingCanvas = (props: CanvasProps<PaperBoxing, PaperBoxingAction>) => {
    const { game, onChooseMove, isLocal } = props;
    const gamePhase = game.gamePhase;
    const gameStateAny: any = game.gameState
    const { players} = game.gameSettings;
    const gameState: PaperBoxingPublicGameState = isLocal ?
      game.renderForPlayer(Array(players.length).fill(null).map((_,i) => i)) :
      gameStateAny  // TODO: type hacks
    const grids = gameState.grids;
    const [clickTimer, setClickTimer] = React.useState<NodeJS.Timer>();

    const touchableCells = players.map((player, playerIndex) =>
        game.availableMoves(playerIndex))

    useEffect(() => {
        return () => {
            if (clickTimer) clearTimeout(clickTimer)
        }
    }, [clickTimer])

    function isSquareTouchable(playerIndex: number, i: number, j: number) {
        if (gameState.ready[playerIndex]) return null
        if (gamePhase !== GamePhase.PLAYING) return null
        if (!isLocal && !PlayerHelper.isOwnedByMe(players[playerIndex])) return null
        const move = touchableCells[playerIndex].find(({row, col}) => row === i && col === j)
        if (!move) return null
        return move.dir
    }

    function isSquareHighlighted(playerIndex: number, cell: PaperBoxingCell, i: number, j: number) {
        if (gamePhase !== GamePhase.PLAYING) return false
        const nextLocation = gameState.nextLocations[playerIndex];
        if (nextLocation && nextLocation.row === i && nextLocation.col === j) {
            // if it's all local players and the click timer expired, then no
            if (!clickTimer && isLocal) return false
            return true;
        }
        if (gameState.ready[playerIndex]) return false
        return !!game.availableMoves(playerIndex).find(({row, col}) => row === i && col === j)
    }

    function onSquareTouch(playerIndex, data, i, j) {
        const dir = isSquareTouchable(playerIndex, i, j);
        if (!dir) return
        onChooseMove({playerIndex, dir});

        setClickTimer(setTimeout(() => {
            setClickTimer(undefined)
        }, 1000))
    }

    function renderSquareOverlay(playerIndex: number, i: number, j: number, cell: PaperBoxingCell) {
        function shouldDrawCircle() {
            if (gamePhase !== GamePhase.PLAYING) return false
            if (gameState.locations[playerIndex].row === i &&
              gameState.locations[playerIndex].col === j) return true

            return false
        }

        if (gamePhase === GamePhase.POST_GAME) {
            return <div className='overlay'>
                {cell.walkedFrom && <ConnectingLine dir={cell.walkedFrom}/>}
            </div>
        }

        if (shouldDrawCircle()) {
            return <div className='winner overlay'/>;
        }
    }

    const playerStyles = players.map(PlayerHelper.getStyleClass)

    function renderCellValue(playerIndex: number, i: number, j: number, cell: PaperBoxingCell) {
        function shouldRender() {
            if (!cell.value) return false
            if (gamePhase === GamePhase.POST_GAME) return true
            if (gameState.locations[playerIndex].row === i &&
              gameState.locations[playerIndex].col === j) return true
            if (!!cell.walkedFrom) return false
            return true
        }

        if (shouldRender()) {
            return gamePhase === GamePhase.PRE_GAME ? '?' : cell.value
        }
    }

    return (
        <div className='PaperBoxingCanvas'>
            <Tabs players={players} renderTabContent={(player, playerIndex) =>
                <Grid
                    className='grid'
                    grid={grids[playerIndex]}
                    squareStyle={() => playerStyles[playerIndex]}
                    isTouchable={(_, i, j) => !!isSquareTouchable(playerIndex, i, j)}
                    isHighlighted={
                        (data, i, j) => isSquareHighlighted(playerIndex, data, i, j)}
                    onTouch={(data, i, j) => onSquareTouch(playerIndex, data, i, j)}
                    renderSquareOverlay={(data, i, j) => renderSquareOverlay(playerIndex, i, j, data)}
                    renderSquareValue={(data, i, j) => renderCellValue(playerIndex, i, j, data)}
                    hashMaterial={[playerIndex]}
                >
                </Grid>
            } />
        </div>
    );
}
