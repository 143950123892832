import {Button, ToggleButton} from '@mui/material'
import Grid from '../../components/game/Grid'
import React from 'react'
import {Either} from '../../common-build/util'
import {Actions} from 'use-undo'
import hash1 from '../../images/hash1.png'
import hash2 from '../../images/hash2.png'
import hash3 from '../../images/hash3.png'
import hash4 from '../../images/hash4.png'
import {countColors} from './utils'

export type ClickMode = Either<{color: number}, {probe: true, forcedProbe?: {row: number, col: number}}>
const images = [hash1, hash2, hash3, hash4]

export function Cell(props: {color: number, scrolls?: boolean}) {
  const {color, scrolls} = props
  if (color === 0) return null
  return <div className={scrolls ? 'scrolling' : ''} style={{
    backgroundImage: `url(${images[color-1]})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'repeat',
    height: '100%',
    width: '100%',
  }
  }></div>
}

interface LAPGridProps {
    grid: number[][];
    gridActions: Actions<number[][]>;
    currentProbe?: {row: number, col: number};
    renderIntersectionOverlay?: (row: number, col: number) => JSX.Element | null;
    colorTargets: number[]; // can be 0 for no target
    clickMode: ClickMode;
    isSolved?: boolean;
    onColorClicked: (color: number) => void;
}

export function LAPGrid(props: LAPGridProps) {

  const {
    grid, gridActions: {set: setGrid, undo, redo, canUndo, canRedo},
    colorTargets, clickMode, isSolved,
    currentProbe, onColorClicked,
    renderIntersectionOverlay }
    = props
  const forcedProbe = clickMode.probe && clickMode.forcedProbe

  const currentColorCounts = countColors(colorTargets.length, grid)

  function onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'z') undo();
    if (event.key === 'y') redo();
    event.stopPropagation()
  }

  function textOnColorButton(color: number) {
    if (colorTargets[color] === 0) return currentColorCounts[color+1]
    return `${currentColorCounts[color+1]}/${colorTargets[color]}`
  }

  function transform(grid: number[][]) {
    return grid.map((gridRow, i) => gridRow.map((cell, j) => {
      return { value: cell }
    }))
  }

  function isHighlighted(row: number, col: number) {
    if (!clickMode.probe) return false
    if (!currentProbe) return false
    return (row === currentProbe.row || row === currentProbe.row - 1) &&
      (col === currentProbe.col || col === currentProbe.col - 1)
  }

  function onTouch(data: any, row: number, col: number) {
    if (clickMode.probe) return
    if (isSolved) return
    const newGrid = grid.map((gridRow, i) => gridRow.map((cell, j) => {
      if (i === row && j === col) {
        return clickMode.color === cell ? 0 : clickMode.color;
      }
      return cell
    }))
    setGrid(newGrid)
  }

  return <div onKeyDown={onKeyDown} tabIndex={-1} className={'hide-focus LAPGrid'}>
    <div className='color-buttons'>
      {colorTargets.map((_, i) =>
        <ToggleButton className='color-button'
                      disabled={!!forcedProbe}
                      key={i} value="check"
                      selected={clickMode.color === i+1}
                      onClick={() => onColorClicked(i)}>
          <div className='color-button-image'>
            <Cell color={i+1} />
          </div>
          <div>
            <span className='color-button-count'>{textOnColorButton(i)}</span>
          </div>
        </ToggleButton>
      )}
    </div>

    <div className={'GridWrapper hover-probes'}>
      <Grid grid={transform(grid)}
            renderIntersectionOverlay={(data, row, col) => renderIntersectionOverlay?.(row, col)}
            isTouchable={(data, row, col) => !clickMode.probe}
            onTouch={onTouch}
            isHighlighted={(data, row, col) => isHighlighted(row, col)}
            renderSquareValue={(data, row, col) => Cell({color: data.value, scrolls:isSolved}) }
      />
      { forcedProbe ? <div className='penalty-probe'>^ Penalty probe added ^</div> : null}
    </div>
    <div>
      <Button onClick={() => undo()} disabled={!canUndo || isSolved || !!forcedProbe}>Undo (z)</Button>
      <Button onClick={() => redo()} disabled={!canRedo || isSolved || !!forcedProbe}>Redo (y)</Button>
    </div>
  </div>
}
