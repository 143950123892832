import {SequenciumEntry} from './sequencium/SequenciumEntry'
import {DandelionsEntry} from "./dandelions/DandelionsEntry";
import {PropheciesEntry} from './prophecies/PropheciesEntry'
import {NeighborsEntry} from './neighbors/NeighborsEntry'
import {PaperBoxingEntry} from "./paper-boxing/PaperBoxingEntry";
import {CanvasProps} from '../common-build/stuff/CanvasProps'
import {FC} from 'react'
import {LAPEntry} from './lap/LAPEntry'
import {TurningPointsEntry} from './turning-points/TurningPointsEntry'

interface BaseGameEntry {
    canonicalName: string;
    displayName: string;
    tagline: string;
    image: string;
    instructions: any;
    isWorkInProgress?: boolean;
}

export type NormalGameEntry = BaseGameEntry & {
    GameClass: any;
    Canvas: FC<CanvasProps<any, any>>;
    CustomElement?: never;
}

export type CustomGameEntry = BaseGameEntry & {
    CustomElement: FC<any>;
}

export type GameEntry = NormalGameEntry | CustomGameEntry

export const GameEntries: GameEntry[] = [
    LAPEntry,
    SequenciumEntry,
    DandelionsEntry,
    PropheciesEntry,
    NeighborsEntry,
    PaperBoxingEntry,
    TurningPointsEntry
    // QuantumTicTacToeEntry
]

export function findGameEntry(canonicalName: string): NormalGameEntry {
    const entry = GameEntries.find(entry => entry.canonicalName === canonicalName)
    if (!entry) {
        throw new Error(`Game entry not found for ${canonicalName}`)
    }
    if (entry.CustomElement) {
        throw new Error(`Game entry for ${canonicalName} is a custom game, not a normal game`)
    }
    return entry
}
