import './Sidenav.scss';
import React from 'react';
import Sitemap from '../app/Sitemap';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';


function Sidenav() {

  const sitemap = new Sitemap.Factory().create();

  function mkLink(label, path, className = '') {
    className = (className || '') + 'item';
    return (
      <NavLink
        key={label}
        to={path}
        className={className}
        activeClassName='selected'>
        {label}
      </NavLink>
    );
  }

  function filter(links) {
    let filtered = links;
    // Show 'showIf=fn()' links only if showIf evaluates true.
    filtered = filtered.filter(i => !i.showIf || i.showIf());
    return filtered;
  }

  let items = filter(sitemap.getVisibleSidenavItems());
  return (
    <div className={'Sidenav ' + (items.length ? '' : 'collapse')}>
      {items.map(i => mkLink(i.label, i.path))}
    </div>
  );
}

export default withRouter(Sidenav);
