import './SequenciumCanvas.scss';
import Grid from '../../components/game/Grid';
import PlayerHelper from '../../players/PlayerHelper';
import React, {useState} from 'react';
import {Sequencium, SequenciumAction} from "../../common-build/games/Sequencium";
import {CanvasProps} from "../../common-build/stuff/CanvasProps";
import {Dir} from "../../common-build/stuff/Dir";
import {ConnectingLine} from "../../components/game/ConnectingLine";




const SequenciumCanvas = (props: CanvasProps<Sequencium, SequenciumAction>) => {
  const [moveFrom, setMoveFrom] = useState<{i?: number, j?: number}>({})

  const game = props.game
  const { gameState, gameSettings } = game

  const activePlayerIndex = gameState.activePlayerIndex;
  const players = gameSettings.players;
  const activePlayer = activePlayerIndex !== null && players[activePlayerIndex];
  const grid = gameState.grid;

  const { validTo, maxScores } = game.buildMetadata();

  const chooseMove = (playerIndex, rowFrom, colFrom, rowTo, colTo) => {
    props.onChooseMove({ playerIndex, rowFrom, colFrom, rowTo, colTo });
  };

  const onMouseUp = (rowTo, colTo) => {
    if (rowTo === undefined || colTo === undefined) {
      setMoveFrom({});
      return;
    }
    let rowFrom = moveFrom.i === undefined ?  rowTo : moveFrom.i;
    let colFrom = moveFrom.j === undefined ?  colTo : moveFrom.j;
    if (rowFrom - rowTo < -1 || rowFrom - rowTo > 1 ||
        colFrom - colTo < -1 || colFrom - colTo > 1 ||
        !validTo[rowTo][colTo] ||
        grid[rowTo][colTo] !== null) {
      setMoveFrom({});
      return;
    }
    if (rowTo === rowFrom && colTo === colFrom) {
      rowFrom = validTo[rowTo][colTo].fromRow;
      colFrom = validTo[rowTo][colTo].fromCol;
    }
    setMoveFrom({});
    chooseMove(gameState.activePlayerIndex, rowFrom, colFrom, rowTo, colTo);
  };

  const getSquareStyle = data => {
    return PlayerHelper.getStyleClass(
        data ? players[data.playerIndex] : activePlayer);
  };

  const renderSquareOverlay = data => {
    return (
      <div className='overlay'>
        { data && data.from && <ConnectingLine dir={data.from} /> }
        {renderMaxScoreCircle(data)}
      </div>
    );
  };


  const renderMaxScoreCircle = data => {
    if (!data) {
      return;
    }
    let maxScore = maxScores[data.playerIndex];
    return data.value === maxScore ?
        <div className='overlay maxScore' /> : null;
  };

  const isSquareTouchable = (data, i, j) => {
    return validTo[i][j];
  };

  const isHighlighted = (data, i, j) => {
    let lastMove = gameState.lastMove
    if (lastMove === null) return false
    return lastMove.row === i && lastMove.col === j
  };

  const onSquareTouch = (data, i, j) => {
    if (data) {
      setMoveFrom({i, j});
    } else {
      onMouseUp(i, j);
    }
  };

  const render = () => {
    return (
      <div className='SequenciumCanvas'>
        <Grid className={`grid gamePhase${game.gamePhase}`}
            grid={grid}
            squareStyle={getSquareStyle}
            isTouchable={isSquareTouchable}
            isHighlighted={isHighlighted}
            renderSquareOverlay={renderSquareOverlay}
            onTouch={onSquareTouch} />
      </div>
    );
  };

  return render();
}


export default SequenciumCanvas;
