import {NormalGameEntry} from '../GameEntries'
import fish from './images/fish.jpg'
import {TurningPoints} from '../../common-build/games/TurningPoints'
import {TurningPointsCanvas} from './TurningPointsCanvas'
import {turningPointsInstructions} from "./TurningPointsInstructions";

export const TurningPointsEntry: NormalGameEntry = {
  canonicalName: "turning-points",
  displayName: "Turning Points",
  tagline: 'A game of runaway rotations.',
  image: fish,
  GameClass: TurningPoints,
  instructions: turningPointsInstructions,
  Canvas: TurningPointsCanvas
}
