/** Lightweight wrapper around superagent. */

import superagent from 'superagent';
import Config from '../../components/app/config';


const _abs_path = path =>
    path.startsWith('http') ? path : Config.serverEndpoint + path;


function httpGet(path) {
  // return superagent.get(path)
  return superagent.get(_abs_path(path))
}

function httpPost(path) {
  // return superagent.post(path)
  return superagent.post(_abs_path(path))
}

export { httpGet, httpPost}
