import image1 from './instructions/media/image1.png'
import {PaperBoxing} from "../../common-build/games/PaperBoxing";
import {PaperBoxingCanvas} from "./PaperBoxingCanvas";
import {PaperBoxingInstructions} from './instructions/PaperBoxingInstructions'

export const PaperBoxingEntry = {
    canonicalName: "paper-boxing",
    displayName: 'Paper Boxing',
    tagline: 'A game of narrow victories.',
    image: image1,
    GameClass: PaperBoxing,
    instructions: PaperBoxingInstructions.getInstructionCards(),
    Canvas: PaperBoxingCanvas
}
