import image1 from './images/image1.png'
import image2 from './images/image2.png'
import image4 from './images/image4.png'

export const turningPointsInstructions = [
    {
        content: <div>
            <p>In this dizzying game, the goal is to wind up with <b>pieces pointing in your direction</b>.</p>
            <p>But watch out — these little (fire)crackers are prone to explosive rotations...</p>
        </div>
    },
    {
        content: <div>
            <p>Take turns placing pieces in empty squares. If your piece points directly at another piece,
            then <b>that piece rotates 90° clockwise</b>.</p>
            <p>But as we'll soon see, rotations can cascade!</p>
        </div>,
        img: image1
    },
    {
        content: <div>
            <p>If a rotated piece is now pointing at another piece, then <b>it also rotates 90° clockwise</b>, and
                so on, until you reach a piece that points at an empty space (or to the edge of the board).</p>
        </div>,
        img: image2
    },
    {
        content: <div>
            <p>When the board is full, you score <b>one point per piece pointing
            toward your side of the board</b>.</p>
        </div>,
        img: image4
    }
]