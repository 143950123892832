import {BaseGameManager} from './BaseGameManager'
import {findGameEntry} from '../../games/GameEntries'
import {GameManager} from './GameManager'
import {BaseGameState} from '../../common-build/stuff/BaseGameState'

export class InMemoryGameManager extends BaseGameManager implements GameManager {

  gameHistory: BaseGameState[] = []

  executeAction(action): void {
    if (this.game) {
      this.gameHistory.push(JSON.parse(JSON.stringify(this.game.gameState)))
      this.game.action(action)
      this.onChanged()
    }
  }

  isReady(): boolean {
    return !!this.game
  }

  newGame(canonicalName: string): void {
    this.game = new (findGameEntry(canonicalName).GameClass)()
    this.onChanged()
  }

  setGamePhase(gamePhase): void {
    if (!this.game) return
    this.game.gamePhase = gamePhase
    this.onChanged()
  }

  setGameSettings(gameSettings): Promise<void> {
    console.log('Setting game settings', gameSettings)
    if (!this.game) return Promise.resolve()
    this.game.gameSettings = gameSettings
    this.game.gameState = this.game.initialGameState(gameSettings)
    console.log('New game state', this.game.gameState)
    this.onChanged()
    return Promise.resolve()
  }

  startGame(): void {
    if (!this.game) return
    this.gameHistory = []
    this.game.start()
    this.onChanged()
  }

  undo() {
    if (!this.game) return
    const lastState = this.gameHistory.pop()
    if (!lastState) return
    this.game.gameState = lastState
    this.onChanged()
  }

  abandonGame(): void {
    throw new Error('Local games cannot be abandoned')
  }

  sit(playerIndex: number): void {
    throw new Error('Local games cannot be sat in')
  }

  stand(): void {
    throw new Error('Local games cannot be stood up from')
  }

  joinGame(gameKey: string): void {
    throw new Error('Local games cannot be joined')
  }

  getJoinLink(): string {
    throw new Error('Local games cannot be joined')
  }
}
