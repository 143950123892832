import './LabelValue.scss';
import InfoBubble from './InfoBubble';
import React from 'react';


/**
 * Renders label-value pairs.
 *
 * Props:
 *   className
 *   styles
 *   label
 *   labelClassName
 *   labelInfoBubble
 *   labelTemplate
 *   value
 *   valueClassName
 *   valueInfoBubble
 *   valueTemplate
 */

interface LabelValueProps {
  className?: string;
  styles?: string[] | string;
  label?: any;
  labelClassName?: string;
  labelInfoBubble?: string;
  labelTemplate?: (item: string) => string;
  value?: any;
  valueClassName?: string;
  valueInfoBubble?: string;
  valueTemplate?: (item: string) => string;
}
function LabelValue(props: LabelValueProps) {

  function renderField(item, className, infoBubble, template) {
    return (
      <div className={className}>
        {(template || (i => i))(item)}
        {infoBubble ? (<InfoBubble text={infoBubble}/>) : ''}
      </div>
    );
  }

  function getStyleClasses() {
    let styles = props.styles || [];
    if (typeof props.styles === typeof '') {
      // @ts-ignore
      styles = [props.styles];
    }

    // @ts-ignore
    return styles.reduce(
      (className, style) => className + style + ' ', '');
  }

  function getClasses() {
    let className = (
      'LabelValue ' +
      props.className + ' ' +
      getStyleClasses());
    return className;
  }

  return (
    <div className={getClasses()}>
      {renderField(
        props.label,
        `label ${props.labelClassName}`,
        props.labelInfoBubble,
        props.labelTemplate,
      )}
      {renderField(
        props.value,
        `value ${props.valueClassName}`,
        props.valueInfoBubble,
        props.valueTemplate,
      )}
    </div>
  );
}

LabelValue.Style = {
  LEFT_RIGHT: 'leftRight',
  LABEL_BOLD: 'labelBold',
  VALUE_BOLD: 'valueBold',
};


export default LabelValue;
