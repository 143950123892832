import React from 'react';


class UnknownPage extends React.Component {
  render() {
    return (
      <div className='UnknownPage'>
        Unknown page.
      </div>
    );
  }
}


export default UnknownPage;
