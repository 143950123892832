import {BaseGameManager} from './BaseGameManager'
import {httpGet, httpPost} from '../../components/http/Http'
import PlayerHelper from '../../players/PlayerHelper'
import {GamePhase} from '../../common-build/stuff/GamePhase'
import {GameBlob} from '../../common-build/stuff/GameBlob'
import {findGameEntry} from '../../games/GameEntries'
import {GameManager} from './GameManager'

export class HttpGameManager extends BaseGameManager implements GameManager {
  gameKey: string
  pollIntervalId: NodeJS.Timer
  lastSeenMillis: number

  constructor() {
    super()
    this.game = null
    this.gameKey = '';
    this.pollIntervalId = setInterval(() => this.poll(), 1000)
    this.lastSeenMillis = 0
  }

  async poll() {
    if (!this.gameKey) return
    if (!this.changeHandler) return  // nobody's listening

    // TODO: consider bolting onto Cosmos's change feed instead of polling.
    const resp = await httpGet('/gameplay/poll')
      .query({
        gameKey: this.gameKey,
        lastSeenMillis: this.lastSeenMillis,
        clientCode: PlayerHelper.clientCode,
      })
    this.processResponse(resp)
  }

  isReady() {
    return !!this.game
  }

  async setGamePhase(gamePhase) {
    if (!this.game) return
    this.game.gamePhase = gamePhase
    const resp = await httpPost('/gameplay/setphase')
      .send({gameKey: this.gameKey, gamePhase})
    this.processResponse(resp)
  }

  async setGameSettings(gameSettings) {
    if (!this.game) return
    this.game.gameSettings = gameSettings
    const resp = await httpPost('/gameplay/setsettings')
      .send({gameKey: this.gameKey, gameSettings})
    this.processResponse(resp)
  }

  getJoinLink() {
    // TODO may want to move this to a helper/util/manager class.
    return `${document.location.origin}/join/${this.gameKey}`
  }

  async newGame(canonicalName: string) {
    this.game = null
    const resp = await httpPost('/gameplay/new')
      .send({ gameType: canonicalName})
    this.processResponse(resp)
  }

  async joinGame(gameKey) {
    const rsp = await httpGet('/gameplay/query')
      .query({
        gameKey,
        clientCode: PlayerHelper.clientCode
      })

    this.gameKey = gameKey;
    this.processResponse(rsp)
    return rsp.body;
  }

  async startGame() {
    if (!this.game) return
    const resp = await httpPost('/gameplay/start')
      .send({gameKey: this.gameKey, gameSettings: this.game.gameSettings})
    this.processResponse(resp)
  }

  async sit(seatIndex: number) {
    if (!this.game) return

    if (PlayerHelper.isOwnedByMe(this.game.gameSettings.players[seatIndex])) {
      await this.stand()
      return
    }
    if (this.game.gameSettings.players.some(PlayerHelper.isOwnedByMe)) return

    const player = this.game.gameSettings.players[seatIndex]
    if (!player) return

    if (player.owner || player.playerType !== 'human') return

    PlayerHelper.claimPlayer(player)
    await this.setGameSettings(this.game.gameSettings)
  }

  async stand() {
    if (!this.game) return

    const player = this.game.gameSettings.players.find(PlayerHelper.isOwnedByMe)
    if (!player) return

    PlayerHelper.unclaimPlayer(player)
    await this.setGameSettings(this.game.gameSettings)
  }

  executeAction(action) {
    if (!this.game) return
    if (this.game.gamePhase !== GamePhase.PLAYING) {
      return
    }
    httpPost('/gameplay/action')
      .send({
        gameKey: this.gameKey,
        clientCode: PlayerHelper.clientCode,
        action,
      })
      .then(this.processResponse.bind(this), console.error)
  }

  processResponse(rsp) {
    if (!rsp || !rsp.body) {
      return
    }

    const blob: GameBlob = rsp.body
    if (!blob.gameType) {
      return
    }

    if (!this.game) {
      this.game = new (findGameEntry(blob.gameType).GameClass)()
    }
    if (!this.game) return

    if (blob.gameState) {
      this.game.gameState = blob.gameState
    }
    if (blob.gamePhase) {
      this.game.gamePhase = blob.gamePhase
    }
    if (blob.gameSettings) {
      this.game.gameSettings = blob.gameSettings
    }
    if (blob.gameKey) {
      this.gameKey = blob.gameKey
    }
    if (rsp.body.lastSeenMillis) {
      this.lastSeenMillis = rsp.body.lastSeenMillis
    }
    this.onChanged()
  }

  async abandonGame() {
    await httpPost('/gameplay/abandon')
      .send({gameKey: this.gameKey})
    this.game = null
    this.onChanged()
  }

}

export const _httpInstance = new HttpGameManager()
