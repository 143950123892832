import './Growl.scss'
import {Toast} from 'primereact/toast'
import React from 'react'

let _el: Toast | null = null;

function GrowlElement() {
  return (
    <Toast
      ref={e => _el = e}
      className="el"
      baseZIndex={10000}/>
  )
}

class GrowlService {
  show(obj) {
    _el?.show(obj);
  }

  static Factory = class {
    create() {
      return _instance
    }
  }
}

const _instance = new GrowlService();

export {
  GrowlElement,
  GrowlService,
};

