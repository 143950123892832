import image1 from './media/image1.png'
import image2 from './media/image2.png'
import image3 from './media/image3.png'
import image4 from './media/image4.png'
import image5 from './media/image5.png'
import image6 from './media/image6.png'

import React from 'react'

const instructionCards = [
  {
    img: image1,
    content: <div>
      <p> As in actual boxing, you and a foe go toe to toe for 15 rounds. But please, no real punches. </p>
      <p> <b>Goal:</b>
        Win a majority of the match’s 15 rounds.
      </p>
    </div>,
  },
  {
    img: image2,
    content: <div>
      <p> You begin in the upper-left corner. Then, each round, you <b>“step” to a neighboring square</b> (by
        clicking on it). </p>
      <p>Your opponent will make their <b>own step at the same time</b>. </p>
    </div>,
  },
  {
    img: image3,
    content: <div>
      <p>
        When both players have taken their steps, the numbers are revealed. </p>
      <p>The <b>higher number scores a point</b>. In case of a tie, neither player scores.</p>
    </div>,
  },
  {
    img: image4,
    content: <div>
      <p>As you go, your path may <b>cross itself along diagonals</b>, but <b>cannot revisit any squares</b>. </p>
    </div>,
  },
  {
    img: image5,
    content: <div>
      <p>If you manage to <b>trap yourself</b>, so that your path cannot continue, then you have in effect
        chosen the number <b>zero for all remaining rounds</b>.</p>
    </div>,
  },
  {
    img: image6,
    content: <div>
      <p>The champion is <b>whoever wins more rounds</b> (i.e., scores more points). Ties are possible.</p>
    </div>,
  }
]

export const PaperBoxingInstructions = {
  getInstructionCards: () => instructionCards,
};
