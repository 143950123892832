import {UnknownGame} from '../../common-build/stuff/Game'
import {InMemoryGameManager} from './InMemoryGameManager'

export interface GameManager {
  isReady(): boolean;

  setChangeHandler(fn: ((game: UnknownGame) => void) | null): void;

  setGamePhase(gamePhase): void;

  setGameSettings(gameSettings): Promise<void>;

  newGame(canonicalName: string): void;

  abandonGame(): void;

  sit(playerIndex: number): void;

  stand(): void;

  startGame(): void;

  executeAction(action): void;

  joinGame(gameKey: string): void;

  getJoinLink(): string;

}

export const _memoryInstance = new InMemoryGameManager()

