import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {createBrowserHistory} from "history";
import {ClickAnalyticsPlugin} from "@microsoft/applicationinsights-clickanalytics-js";

const browserHistory = createBrowserHistory({ basename: '' });

export const reactPlugin = new ReactPlugin();
const clickPluginInstance = new ClickAnalyticsPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=4b3d5d73-8e7a-4f2f-b520-9fcd4e8178ee;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
    extensions: [
        reactPlugin,
        clickPluginInstance
    ],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: { autoCapture: true }
    }
  }
});
appInsights.loadAppInsights();
