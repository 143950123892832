import {CanvasProps} from '../../common-build/stuff/CanvasProps'
import {TurningPoints, TurningPointsAction} from '../../common-build/games/TurningPoints'
import Grid from '../../components/game/Grid'
import {ReactElement, useEffect, useLayoutEffect, useState} from 'react'
import {Dir, rotate90} from '../../common-build/stuff/Dir'
import './TurningPointsCanvas.scss'
import {GamePhase} from '../../common-build/stuff/GamePhase'
import fish from './images/fish.jpg'
import PlayerHelper from '../../players/PlayerHelper'
import {Button} from '@mui/material'

function transformGrid(grid: TurningPoints['gameState']['grid']) {
  return grid.map(row => row.map(cell => cell === null ? null : {value: cell}))
}

export function TurningPointsCanvas(props: CanvasProps<TurningPoints, TurningPointsAction>): ReactElement {
  const game = props.game

  const {gameState, gameSettings, gamePhase} = game
  const lastMove = gameState.lastMove

  const [selectedCell, setSelectedCell] = useState<{ row: number, col: number } | null>(null)
  const [playingSpin, setPlayingSpin] = useState<number>()

  const activeSpin = playingSpin === undefined || !lastMove
    ? undefined
    : lastMove.spins[playingSpin]

  const [grid, setGrid] = useState(gameState.grid)

  useEffect(() => {
    setGrid(gameState.grid)
  }, [gameState.grid])

  useLayoutEffect(() => {
    if (lastMove && lastMove.spins?.length) {
      setPlayingSpin(0)
      const grid = lastMove.originalGrid
      grid[lastMove.playedRow][lastMove.playedCol] = lastMove.playedDir
      setGrid(grid)
    } else {
      setPlayingSpin(undefined)
      setGrid(gameState.grid)
    }
  }, [lastMove])

  function onTouch(_, row: number, col: number) {
    if (grid[row][col] !== null) return
    setSelectedCell({row, col})
  }

  const DirSelector = (props: { onChoose: (dir: Dir | null) => void }) => {
    if (!selectedCell) return null
    const validDirs = [Dir.N, Dir.E, Dir.W, Dir.S]
    return <div className='directionSelector shadow'>
      <div className='directions-container'>
        {validDirs.map(dir => <div className='direction-fish'>
          <Fish dir={dir} key={dir} onClick={() => props.onChoose(dir)}/>
        </div>)}
      </div>
      <div className='cancel-container'>
        <Button variant='contained' onClick={() => props.onChoose(null)}>Cancel</Button>
      </div>
    </div>
  }

  function renderSquareOverlay(data: TurningPoints['gameState']['grid'][number][number], row: number, col: number) {
    function onChoose(dir: Dir | null) {
      if (dir) props.onChooseMove({row, col, dir})
      setSelectedCell(null)

    }

    if (selectedCell && selectedCell.row === row && selectedCell.col === col) {
      return <DirSelector onChoose={onChoose}/>
    }

    if (gamePhase === GamePhase.POST_GAME) {
      const playerDirs = game.playerDirs()
      if (data && playerDirs.indexOf(data) !== -1) return <div className='winner'/>
    }
  }

  function Fish(props: {dir: Dir, onClick?: () => void}) {
    const rotation =
      props.dir === Dir.N ? 270 :
      props.dir === Dir.E ? 0 :
      props.dir === Dir.S ? 90 :
      props.dir === Dir.W ? 180
      : 0
    return <div className='fish-container' onClick={() => props.onClick?.()}>
      <img src={fish} className='fish' style={{transform: `rotate(${rotation}deg)`}}/>
    </div>
  }

  function renderSquareValue(data: TurningPoints['gameState']['grid'][number][number], row: number, col: number) {
    if (data === null) return null
    const spinning = activeSpin !== undefined && activeSpin?.row === row && activeSpin?.col === col

    function finishedSpinning() {
      if (!lastMove) return
      if (!activeSpin) return
      if (playingSpin === undefined) return
      if (playingSpin === lastMove.spins.length - 1) {
        setPlayingSpin(undefined)
        setGrid(gameState.grid)
      } else {
        setGrid(grid.map((row, r) => row.map((cell, c) => {
          if (r === activeSpin.row && c === activeSpin.col && cell) return rotate90(cell)
          return cell
        })))
        setPlayingSpin(playingSpin + 1)
      }
    }

    return <div className={'cell-container ' + (spinning ? 'spinning' : '')} onAnimationEnd={finishedSpinning}>
      <Fish dir={data} />
    </div>
  }

  function isTouchable(data: TurningPoints['gameState']['grid'][number][number], row: number, col: number) {
    return gamePhase === GamePhase.PLAYING && data === null && !activeSpin
  }

  function Avatar(props: {player: any, size: number, backwards?: boolean}) {
    return <div style={{height: props.size, width: props.size}}>
      <img style={{
        width: '100%',
        transform: props.backwards ? 'scaleX(-1)' : ''
      }}
           src={PlayerHelper.getAvatar(props.player)}
           className='avatar'
           alt='avatar'
      />
    </div>
  }

  function squareStyle(data: TurningPoints['gameState']['grid'][number][number], row: number, col: number) {
    if (gamePhase === GamePhase.POST_GAME) {
      const playerDirs = game.playerDirs()
      const playerIndex = playerDirs.findIndex(dir => dir === grid[row][col])
      if (playerIndex !== -1) return PlayerHelper.getStyleClass(gameSettings.players[playerIndex])
      return null
    }
    return PlayerHelper.getStyleClass(gameSettings.players[gameState.activePlayerIndex || 0])
  }

  const isFourPlayer = gameSettings.players.length === 4

  return <div className='TurningPointsCanvas'>
    <Avatar size={50} player={gameSettings.players[0]} />
    <div className='middle-section'>
      { isFourPlayer && <Avatar size={50} player={gameSettings.players[1]} /> }
      <Grid
        grid={grid}
        squareStyle={squareStyle}
        // grid={transformGrid(grid)}
        onTouch={onTouch}
        renderSquareOverlay={renderSquareOverlay}
        renderSquareValue={renderSquareValue}
        isTouchable={isTouchable}
      />
      { isFourPlayer && <Avatar size={50} player={gameSettings.players[3]} backwards /> }
    </div>
    { isFourPlayer && <Avatar size={50} player={gameSettings.players[2]} backwards /> }
    { !isFourPlayer && <Avatar size={50} player={gameSettings.players[1]} backwards /> }
  </div>
}
